import { Card, Image, Skeleton, Tooltip } from '@mantine/core';
import { FaStoreAlt } from 'react-icons/fa';
import { HiOutlineBuildingStorefront } from 'react-icons/hi2';
import { Link, Navigate } from 'react-router-dom';
import { IMerchant } from '../../interface/Merchant';
import MerchantLayout from '../../layout/merchant';
import MerchantHooks from './MerchantHooks';
import { Button } from '@/components/ui/button';
import { useMerchants } from '@/hooks/use-merchant';
import { CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { LogOut } from 'lucide-react';

const Merchant = () => {
    const { handleSelectMerchant, handleLogout } = MerchantHooks();

    const { merchantData, isFetching } = useMerchants();

    if (localStorage.getItem('token') === null) {
        return <Navigate to='/login' replace />;
    }

    if (localStorage.getItem('merchantID') !== null) {
        return <Navigate to='/app/dashboard' replace />;
    }

    return (
        <MerchantLayout withLogout>
            <Card className='p-0 overflow-auto'>
                <CardHeader className='p-0 border-b'>
                    <CardTitle className='p-4'>Daftar Toko</CardTitle>
                </CardHeader>
                <CardContent className='p-4 space-y-4'>
                    <div>
                        {/* loading */}
                        {isFetching && <Skeleton height={66} width='100%' radius='sm' />}

                        {/* list merchant */}
                        {!isFetching &&
                            merchantData.map((each: IMerchant, key: number) => (
                                <div
                                    className='flex items-center justify-between mb-3 rounded cursor-pointer '
                                    key={key}
                                >
                                    <div className='flex gap-3 grow' onClick={() => handleSelectMerchant(each.id)}>
                                        <Image
                                            width={50}
                                            height={50}
                                            fit='cover'
                                            src={each.image}
                                            withPlaceholder
                                            placeholder={<HiOutlineBuildingStorefront className='text-3xl' />}
                                        />
                                        <div>
                                            <div className='mb-1 font-medium'>{each.name}</div>
                                            <div className='text-xs text-gray-500'>
                                                {each.address.length > 35 ? (
                                                    <Tooltip label={each.address}>
                                                        <div className='w-fit'>{each.address.substr(0, 35)}...</div>
                                                    </Tooltip>
                                                ) : (
                                                    <div>{each.address}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <Link to={`/merchant/edit/${each.id}`}>
                                        <Button variant='outline'>Edit</Button>
                                    </Link>
                                </div>
                            ))}

                        {/* empty state */}
                        {!isFetching && merchantData.length === 0 && (
                            <div className='py-5'>
                                <FaStoreAlt className='mx-auto text-blue-700 text-7xl' />
                                <div className='text-lg font-semibold text-center text-gray-500'>
                                    Anda belum memiliki toko
                                </div>
                                <div className='text-xs text-center text-gray-500'>
                                    Silahkan tambahkan toko untuk mulai menggunakan aplikasi
                                </div>
                            </div>
                        )}
                    </div>

                    <div className='flex flex-col gap-2'>
                        {localStorage.getItem('role') === 'owner' && (
                            <Link to='/merchant/create'>
                                <Button className='w-full'>Tambah Toko</Button>
                            </Link>
                        )}
                    </div>
                </CardContent>
            </Card>
            <Button className='w-full mt-2 text-destructive' variant={'outline'} onClick={handleLogout}>
                <LogOut />
                Keluar
            </Button>
        </MerchantLayout>
    );
};

export default Merchant;
