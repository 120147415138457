import { Loader2 } from 'lucide-react';
import { Button } from './ui/button';
import React from 'react';
import { UseFormOngoingTransactionHook } from '@/hooks/use-transaction';
import { ITransactionItem } from '@/interface/Transaction';
import { formatRupiah } from '@/lib/helper';
import { Card, CardContent, CardHeader } from './ui/card';
import { Separator } from './ui/separator';
import Calculator from './calculator';
import { SkeletonDetail } from './skeletons';
import dayjs from 'dayjs';
import { Badge } from './ui/badge';

const DetailTransaction: React.FC<UseFormOngoingTransactionHook> = ({
    detailTransaction,
    control,
    errors,
    watch,
    cart,
    handleSubmit,
    setIsPay,
    isPay,
    processing,
    isFetchingDetail,
    isCancelling,
    handleCancelTransaction,
}) => {
    return (
        <div className='space-y-2'>
            {detailTransaction && (
                <>
                    <Card className='w-full '>
                        <CardHeader className='flex flex-row items-center justify-between p-2'>
                            Ringkasan Pesanan
                        </CardHeader>
                        <Separator />
                        <CardContent className='p-2 space-y-2 text-sm font-medium'>
                            {isFetchingDetail && <SkeletonDetail />}

                            {!isFetchingDetail && (
                                <>
                                    <div className='flex justify-between'>
                                        <div>Kode Transaksi</div>
                                        <b className='text-xs'>#{detailTransaction.code}</b>
                                    </div>
                                    <div className='flex justify-between'>
                                        <div>Status</div>
                                        <Badge
                                            style={{ backgroundColor: `${detailTransaction.status_color}` }}
                                            className='text-primary'
                                        >
                                            {detailTransaction.status}
                                        </Badge>
                                    </div>
                                    <Separator />
                                    {cart.map((val: ITransactionItem) => {
                                        return (
                                            <div className='flex justify-between'>
                                                <div>- {val.product_name}</div>
                                                <div>
                                                    {val.qty} x {formatRupiah(val.price)} ={' '}
                                                    {formatRupiah(val.qty * val.price)}
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <Separator />

                                    <div className='flex justify-between'>
                                        <div>Sub Total</div>
                                        <div>{formatRupiah(detailTransaction.total_price)}</div>
                                    </div>
                                </>
                            )}
                        </CardContent>
                    </Card>
                    <Card className='w-full '>
                        <CardContent className='p-0'>
                            <div className='flex justify-between p-2'>
                                <div>Tanggal Transaksi</div>
                                <div>{dayjs(detailTransaction.created_at).format('D MMMM YYYY, HH:mm')}</div>
                            </div>
                            <Separator />
                            <div className='flex justify-between p-2'>
                                <div>Nama Pelanggan</div>
                                <div>{detailTransaction.customer_name}</div>
                            </div>
                            <Separator />
                            <div className='flex justify-between p-2'>
                                <div>Diskon</div>
                                <div>{formatRupiah(detailTransaction.discount)}</div>
                            </div>
                        </CardContent>
                    </Card>
                    {detailTransaction.status === 'PROCESSING' && (
                        <React.Fragment>
                            <Button className='w-full rounded-lg' onClick={setIsPay} disabled={isCancelling}>
                                Bayar Sekarang
                            </Button>
                            <Button
                                className='w-full rounded-lg'
                                variant={'destructive'}
                                disabled={isCancelling}
                                onClick={handleCancelTransaction}
                            >
                                {isCancelling && <Loader2 />}
                                Batalkan
                            </Button>
                        </React.Fragment>
                    )}

                    <Calculator
                        control={control}
                        open={isPay}
                        setOpen={setIsPay}
                        watch={watch}
                        subTotal={detailTransaction.total_price}
                        handleSubmit={handleSubmit}
                        errors={errors}
                        processing={processing}
                    />
                </>
            )}
        </div>
    );
};

export default DetailTransaction;
