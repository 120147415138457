import { Input, Modal, PasswordInput } from '@mantine/core';
import CreateHooks from './CreateHooks';
import { Button } from '@/components/ui/button';
import { Loader2, Plus } from 'lucide-react';

type Props = {
    refetch: () => void;
};

const Create: React.FC<Props> = (props) => {
    const { refetch } = props;
    const { register, handleSubmit, errors, modal, setModal, loading, handleCreateUser } = CreateHooks(refetch);

    return (
        <div>
            <Button onClick={() => setModal(true)}>
                <Plus />
                Tambah Karyawan
            </Button>

            <Modal opened={modal} onClose={() => setModal(false)} withCloseButton={false} padding={20} radius='md'>
                <div className='mb-5 text-xl font-semibold text-center'>Tambah Karyawan</div>
                <div className='flex flex-col gap-3'>
                    {/* nama pengguna */}
                    <Input.Wrapper
                        label='Nama Pengguna'
                        required
                        error={errors?.name?.message ? String(errors?.name?.message) : null}
                    >
                        <Input
                            {...register('name', {
                                required: {
                                    value: true,
                                    message: 'Nama pengguna wajib diisi',
                                },
                            })}
                            placeholder='input nama pengguna'
                            error={errors?.name?.message ? String(errors?.name?.message) : null}
                        />
                    </Input.Wrapper>
                    {/* email */}
                    <Input.Wrapper
                        label='Email'
                        required
                        error={errors?.email?.message ? String(errors?.email?.message) : null}
                    >
                        <Input
                            {...register('email', {
                                required: {
                                    value: true,
                                    message: 'Email wajib diisi',
                                },
                            })}
                            placeholder='input alamat email'
                            error={errors?.email?.message ? String(errors?.email?.message) : null}
                        />
                    </Input.Wrapper>
                    {/* role */}
                    {/* <Controller
                        name='role'
                        control={control}
                        render={({ field }) => (
                            <Select
                                {...field}
                                label='Role'
                                placeholder='pilih role pengguna'
                                required
                                data={[
                                    { value: 'admin', label: 'Admin' },
                                    { value: 'super_user', label: 'Super User' },
                                ]}
                                error={errors?.role?.message ? String(errors?.role?.message) : null}
                            />
                        )}
                    /> */}
                    {/* password */}
                    <PasswordInput
                        {...register('password', {
                            required: {
                                value: true,
                                message: 'Password wajib diisi',
                            },
                        })}
                        required
                        placeholder='Input password'
                        label='Password'
                        error={errors?.password?.message ? String(errors?.password?.message) : null}
                    />
                    <div className='flex justify-end gap-2 pt-5'>
                        <Button variant='outline' color='red' onClick={() => setModal(false)}>
                            Batal
                        </Button>
                        <Button type='submit' className='bg-blue-700' onClick={handleSubmit(handleCreateUser)}>
                            {loading && <Loader2 />}
                            Tambah Pengguna
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Create;
