import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from './ui/dialog';
import { Separator } from './ui/separator';
import { Button } from './ui/button';
import { Control, Controller, FieldErrors, UseFormWatch } from 'react-hook-form';
import { clsx } from '@mantine/core';
import { formatRupiah } from '@/lib/helper';
import { Loader } from 'lucide-react';

export default function Calculator({
    control,
    watch,
    handleSubmit,
    subTotal,
    open,
    setOpen,
    processing,
}: {
    open: boolean;
    setOpen: () => void;
    control: Control<{ total_payment: number; discount: number; customer_name: string }>;
    watch: UseFormWatch<{ total_payment: number; discount: number; customer_name: string }>;
    errors: FieldErrors<{
        customer_name: string;
        discount: number;
        total_payment: number;
    }>;
    handleSubmit: () => void;
    subTotal: number;
    processing: boolean;
}) {
    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent className='max-w-xs gap-2'>
                <DialogHeader>
                    <DialogTitle>Ringkasan Pembayaran</DialogTitle>
                    <DialogDescription />
                </DialogHeader>
                <div className={clsx('p-2 mt-2 space-y-2 rounded-lg bg-muted')}>
                    <Controller
                        name='total_payment'
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Nominal pembayaran wajib diisi!',
                            },
                            min: {
                                value: Number(subTotal),
                                message: 'Nominal uang kurang dari total belanja!',
                            },
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <>
                                <input
                                    {...field}
                                    className='w-full p-2 text-2xl text-right bg-transparent rounded-lg outline-none placeholder:text-2xl'
                                    placeholder='0'
                                    autoComplete='off'
                                    value={field.value ? formatRupiah(field.value) : ''}
                                    onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}
                                />
                                {error && <p className='mt-1 text-sm text-red-500'>{error.message}</p>}
                            </>
                        )}
                    />
                    <Separator />
                    <div>
                        <div className='text-sm text-right'>Diskon : {watch('discount')}</div>
                        <div className='text-sm text-right'>
                            Kembalian : {formatRupiah(watch('total_payment') - Number(subTotal))}
                        </div>
                    </div>
                </div>
                <Button className='rounded-xl' onClick={handleSubmit} disabled={processing}>
                    {processing && <Loader />}
                    Simpan
                </Button>
            </DialogContent>
        </Dialog>
    );
}
