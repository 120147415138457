// import { TrendingUp } from 'lucide-react';
import { Bar, BarChart, CartesianGrid, LabelList, XAxis } from 'recharts';
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart';

const chartConfig = {
    desktop: {
        label: 'Desktop',
        color: 'hsl(var(--chart-1))',
    },
    mobile: {
        label: 'Mobile',
        color: 'hsl(var(--chart-2))',
    },
    label: {
        color: 'hsl(var(--background))',
    },
} satisfies ChartConfig;

export default function SalesStatistics({ data }: { data: any }) {
    const chartData = [
        {
            month: 'January',
            desktop: data?.data?.January?.total_item,
            mobile: data?.data?.January?.total_item,
        },
        {
            month: 'February',
            desktop: data?.data?.February?.total_item,
            mobile: data?.data?.February?.total_item,
        },
        {
            month: 'March',
            desktop: data?.data?.March?.total_item,
            mobile: data?.data?.January?.March,
        },
        {
            month: 'April',
            desktop: data?.data?.April?.total_item,
            mobile: data?.data?.January?.total_item,
        },
        {
            month: 'May',
            desktop: data?.data?.May?.total_item,
            mobile: data?.data?.May?.total_item,
        },
        {
            month: 'Juni',
            desktop: data?.data?.June?.total_item,
            mobile: data?.data?.June?.total_item,
        },
        {
            month: 'Juli',
            desktop: data?.data?.July?.total_item,
            mobile: data?.data?.July?.total_item,
        },
        {
            month: 'Agustus',
            desktop: data?.data?.August?.total_item,
            mobile: data?.data?.August?.total_item,
        },
        {
            month: 'September',
            desktop: data?.data?.September?.total_item,
            mobile: data?.data?.September?.total_item,
        },
        {
            month: 'October',
            desktop: data?.data?.October?.total_item,
            mobile: data?.data?.October?.total_item,
        },
        {
            month: 'November',
            desktop: data?.data?.November?.total_item,
            mobile: data?.data?.November?.total_item,
        },
        {
            month: 'Desember',
            desktop: data?.data?.December?.total_item,
            mobile: data?.data?.December?.total_item,
        },
    ];

    return (
        <ChartContainer config={chartConfig} className='max-h-[300px] w-full'>
            <BarChart
                accessibilityLayer
                data={chartData}
                margin={{
                    top: 20,
                }}
            >
                <CartesianGrid vertical={false} />
                <XAxis
                    dataKey='month'
                    tickLine={false}
                    tickMargin={10}
                    axisLine={false}
                    tickFormatter={(value) => value.slice(0, 3)}
                />
                <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
                <Bar dataKey='desktop' fill='var(--color-desktop)' radius={8}>
                    <LabelList position='top' offset={12} className='fill-foreground' fontSize={12} />
                </Bar>
            </BarChart>
        </ChartContainer>
    );
}
