import { Badge, Image, Loader, Select } from '@mantine/core';
// import { DatePickerInput } from '@mantine/dates';
import dayjs from 'dayjs';
import numeral from 'numeral';
import { BsFillBox2HeartFill } from 'react-icons/bs';
import { FaCartPlus } from 'react-icons/fa';
import { TbBaguette } from 'react-icons/tb';
import { ILowStockProduct } from '../../interface/Report';
import DashboardHooks from './DashboardHooks';
import SalesStatistics from './sales-statistics';

const Dashboard = () => {
    const {
        // firstday,
        // lastday,
        // params,
        summaryParams,
        setSummaryParams,
        transactionReport,
        isFetchingTransactionReport,
        transactionReportSummary,
        // handleFilterDate,
        generateYearOption,
    } = DashboardHooks();

    return (
        <div className='flex flex-col'>
            <div className='flex items-center justify-between'>
                <div className='py-4 text-xl font-semibold'>Dashboard</div>
                {/* <DatePickerInput
                    type='range'
                    valueFormat='D MMMM YYYY'
                    placeholder='Tanggal Awal - Tanggal Akhir'
                    onChange={(value: any) => handleFilterDate(value)}
                    miw={250}
                    className='bg-white border-0'
                    value={[
                        new Date(params?.start_date ?? new Date()),
                        params?.end_date ? new Date(params?.end_date) : null,
                    ]}
                    clearable={params?.start_date !== firstday || params?.end_date !== lastday}
                /> */}
            </div>
            <div className='flex flex-col gap-3'>
                <div className='grid gap-4 auto-rows-min md:grid-cols-3'>
                    {/* total produk terjual */}
                    <div className='flex items-end p-5 border aspect-auto rounded-xl bg-muted/50'>
                        <div className='flex flex-col gap-2'>
                            <div className='text-2xl font-bold'>
                                {/* loading */}
                                {isFetchingTransactionReport && <Loader size='sm' />}

                                {/* showing data */}
                                {!isFetchingTransactionReport && (transactionReport?.data?.items_sold ?? 0)}
                            </div>
                            <div className='font-semibold'>Total Item Terjual</div>
                        </div>
                    </div>
                    {/* total pendapatan */}
                    <div className='flex items-end p-5 border aspect-auto rounded-xl bg-muted/50'>
                        <div className='flex flex-col gap-2'>
                            <div className='text-2xl font-bold'>
                                {/* loading */}
                                {isFetchingTransactionReport && <Loader size='sm' />}

                                {/* showing data */}
                                {!isFetchingTransactionReport && (
                                    <span>Rp {numeral(transactionReport?.data?.sales_amount ?? 0).format()}</span>
                                )}
                            </div>
                            <div className='font-semibold'>Total Pendapatan</div>
                        </div>
                    </div>
                    {/* penjualan terbaik */}
                    <div className='flex items-end p-5 border aspect-auto rounded-xl bg-muted/50'>
                        <div className='flex flex-col gap-2 grow'>
                            <div className='flex flex-col gap-1.5'>
                                {/* loading */}
                                {isFetchingTransactionReport && <Loader size='sm' />}

                                {/* empty state */}
                                {!isFetchingTransactionReport && !transactionReport?.data?.best_selling_item && (
                                    <div className='flex flex-col gap-1.5 py-3'>
                                        <FaCartPlus className='mx-auto text-4xl text-blue-600' />
                                        <div className='text-xs text-center text-gray-500'>
                                            Segera lakukan transaksi. <br />
                                            Untuk mengetahui produk terbaik.
                                        </div>
                                    </div>
                                )}

                                {/* showing data */}
                                {!isFetchingTransactionReport && transactionReport?.data?.best_selling_item && (
                                    <div className='flex gap-2'>
                                        <Image
                                            width={45}
                                            height={45}
                                            fit='cover'
                                            src={transactionReport?.data?.best_selling_item?.image}
                                            withPlaceholder
                                            placeholder={<TbBaguette className='text-3xl' />}
                                            className='shadow'
                                            radius='lg'
                                        />
                                        <div className='flex flex-col gap-0.5'>
                                            <div className='font-semibold'>
                                                {transactionReport?.data?.best_selling_item?.name}
                                            </div>
                                            <div className='text-xs'>
                                                Terjual {transactionReport?.data?.best_selling_item?.total_sold ?? 0}{' '}
                                                item
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className='font-semibold'>Penjualan Terbaik</div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* statistik pendapatan */}
                <div className='grid gap-3 auto-rows-min md:grid-cols-3'>
                    <div className='md:col-span-2'>
                        <div className='p-5 bg-white border rounded-md '>
                            <div className='flex items-center justify-between'>
                                <div className='text-sm font-semibold'>Statistik Penjualan</div>
                                <Select
                                    maw={100}
                                    placeholder='2023'
                                    data={generateYearOption()}
                                    size='sm'
                                    value={summaryParams}
                                    onChange={(value) => setSummaryParams(value ?? String(dayjs().year()))}
                                />
                            </div>
                            <SalesStatistics data={transactionReportSummary} />
                        </div>
                    </div>
                    {/* produk segera habis */}
                    <div className='bg-white p-5 border rounded-md text-sm max-h-[489px] overflow-auto'>
                        <div className='flex flex-col gap-2'>
                            <div>
                                <div className='font-semibold'>Produk Segera Habis</div>
                            </div>
                            <div className='flex flex-col gap-2'>
                                {/* loading */}
                                {isFetchingTransactionReport && <Loader size='sm' />}

                                {/* empty state */}
                                {!isFetchingTransactionReport && transactionReport?.data?.low_stock?.length === 0 && (
                                    <div className='flex flex-col gap-2 py-5'>
                                        <BsFillBox2HeartFill className='mx-auto text-4xl text-blue-600' />
                                        <div className='text-xs text-center text-gray-500'>
                                            Tenang, stock produk mu aman. <br />
                                            Lakukan pengecekan secara berkala ya.
                                        </div>
                                    </div>
                                )}

                                {/* showing data */}
                                {!isFetchingTransactionReport &&
                                    transactionReport?.data?.low_stock?.map((datum: ILowStockProduct, key: number) => (
                                        <div className='flex gap-3 px-1.5 py-2.5 shadow rounded' key={key}>
                                            <Image
                                                width={40}
                                                height={40}
                                                fit='cover'
                                                src={datum?.image}
                                                withPlaceholder
                                                placeholder={<TbBaguette className='text-3xl' />}
                                                className='shadow'
                                                radius='lg'
                                            />
                                            <div className='flex flex-col gap-1'>
                                                <div className='font-semibold'>{datum?.name}</div>
                                                <div className='flex items-center gap-2'>
                                                    <div className='text-xs'>{datum?.stock}</div>
                                                    {datum?.stock === 0 && (
                                                        <Badge color='red' size='sm'>
                                                            Habis
                                                        </Badge>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div></div>
            </div>
        </div>
    );
};

export default Dashboard;
