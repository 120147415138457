import { ClipboardCheck, LayoutDashboard, LayoutList, ShoppingBag, SquareUserRound } from 'lucide-react';

export default function useMenus() {
    const menus = [
        {
            icon: LayoutDashboard,
            name: 'Dashboard',
            url: '/app/dashboard',
            isShowing: true,
        },
        {
            icon: ShoppingBag,
            name: 'Kasir',
            url: '/app/cashier',
            isShowing: true,
        },
        {
            icon: LayoutList,
            name: 'Produk',
            url: '/app/product',
            isShowing: true,
        },
        {
            icon: ClipboardCheck,
            name: 'Transaksi',
            url: '/app/transaction',
            isShowing: true,
        },
        {
            icon: SquareUserRound,
            name: 'Karyawan',
            url: '/app/user',
            isShowing: localStorage.getItem('role') === 'owner' ? true : false,
        },
    ];

    return { menus };
}
