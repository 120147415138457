import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import {
    CreateMerchant,
    Dashboard,
    DetailProduct,
    EditMerchant,
    Login,
    Merchant,
    ProductCategory,
    Products,
    Register,
    Transaction,
    User,
} from './pages';
import AppPage from './pages/app';
import Cashier from './pages/cashier';

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path='/'>
            <Route path='/' element={<Navigate to='login' replace />} />

            {/* login */}
            <Route path='login' element={<Login />} />

            {/* register */}
            <Route path='register' element={<Register />} />

            {/* merchant */}
            <Route path='merchant' element={<Merchant />} />
            <Route path='merchant/create' element={<CreateMerchant />} />
            <Route path='merchant/edit/:id' element={<EditMerchant />} />

            <Route path='app' element={<AppPage />}>
                <Route path='dashboard' element={<Dashboard />} />

                {/* Product Category */}
                <Route path='product-cetegory' element={<ProductCategory />} />
                <Route path='cashier' element={<Cashier />} />

                {/* Products */}
                <Route path='product' element={<Products />} />
                <Route path='product/detail/:id' element={<DetailProduct />} />

                {/* Transaction */}
                <Route path='transaction' element={<Transaction />} />

                {/* user */}
                <Route path='user' element={<User />} />
            </Route>
        </Route>
    )
);

export default router;
