import Category from './category';
import Create from './create';
import { DataTable } from '@/components/data-table';
import { columns } from './columns';
import Search from '@/components/search';
import { useProductParams, useProducts } from '@/hooks/use-product';

const Products = () => {
    const { products, refetch, total_page, current_page } = useProducts();
    const { value: search, setValue: setSearch } = useProductParams('q');
    const { setValue: setPage } = useProductParams('page', 0);

    return (
        <div className='flex flex-col'>
            <div className='flex justify-between'>
                <div className='py-4 text-xl font-semibold'>Produk</div>
            </div>
            <div className='flex flex-col gap-2'>
                <div className='flex flex-col justify-between gap-2 bg-white md:flex-row'>
                    <Search value={search} onChange={(e) => setSearch(e.target.value)} />
                    <div className='flex gap-2'>
                        <Category />
                        <Create refetch={refetch} />
                    </div>
                </div>

                <DataTable
                    columns={columns}
                    data={products}
                    total_page={total_page}
                    current_page={Number(current_page)}
                    onChangePage={(val) => setPage(String(val))}
                />
            </div>
        </div>
    );
};

export default Products;
