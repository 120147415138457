import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

function MerchantHooks() {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const handleSelectMerchant = (id: number) => {
        localStorage.setItem('merchantID', String(id));
        navigate('/app/dashboard');
    };

    const handleLogout = () => {
        queryClient.removeQueries(['merchants']);
        queryClient.clear();
        localStorage.clear();
        navigate('/login');
    };

    return {
        handleSelectMerchant,
        handleLogout,
    };
}

export default MerchantHooks;
