import { SkeletonProduct } from '@/components/skeletons';
import { AspectRatio } from '@/components/ui/aspect-ratio';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { useAddToCart, useCart } from '@/hooks/use-cart';
import { useProducts } from '@/hooks/use-product';
import { IProduct } from '@/interface/Product';
import { formatRupiah } from '@/lib/helper';
import { clsx } from '@mantine/core';
import { Plus, ShoppingBag, X } from 'lucide-react';
import { TbBaguette } from 'react-icons/tb';

export default function ProductList() {
    const merchantID = localStorage.getItem('merchantID')!;
    const { mutate: addToCart } = useAddToCart(merchantID);
    const { data } = useCart(merchantID);
    const carts = data?.cart ?? [];

    const { products, isFetching } = useProducts();
    if (isFetching) {
        return <SkeletonProduct />;
    }
    if (products.length == 0) {
        return (
            <div className='flex flex-col items-center justify-center min-h-[300px] gap-2'>
                <ShoppingBag size={60} />
                <div className='text-center'>
                    <h2 className='text-xl'>Produk Belum Tersedia!</h2>
                    <p className='max-w-sm'>Belum ada produk yang ditambahkan. Harap tunggu hingga produk tersedia.</p>
                </div>
            </div>
        );
    }
    return (
        <div className='grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 '>
            {products?.map((product: IProduct, key: number) => {
                const cart = carts.find((each) => Number(each.id) === product.id);

                return (
                    <div key={key} className='flex flex-col gap-2 p-2 space-y-2 bg-white border rounded-md'>
                        <div className='relative'>
                            <Badge
                                className={clsx(
                                    'absolute z-10  top-2 right-2 text-primary',
                                    product.stock > 0 ? 'bg-white' : 'bg-primary text-white'
                                )}
                            >
                                <div
                                    className={clsx(
                                        'w-2 h-2 mr-2  rounded-full',
                                        product.stock > 0 ? 'bg-green-500' : 'bg-red-500'
                                    )}
                                />{' '}
                                {product.stock > 0 ? 'Tersedia' : 'Kosong'}
                            </Badge>
                            <Avatar className='w-full h-[100px] md:h-[150px] rounded-lg'>
                                <AspectRatio ratio={4 / 3}>
                                    <AvatarImage src={product.cover?.url} alt={product.name} className='object-cover' />
                                    <AvatarFallback className='rounded-lg'>
                                        <TbBaguette className='text-3xl' />
                                    </AvatarFallback>
                                </AspectRatio>
                            </Avatar>
                        </div>
                        <div className='flex flex-col justify-between flex-1 gap-2'>
                            <div>
                                <h2 className='flex-1 text-sm line-clamp-1 text-primary/80'>{product.name}</h2>
                                <p className='text-sm font-bold'>{formatRupiah(product.selling_price)}</p>
                            </div>
                            {product?.stock > 0 ? (
                                <>
                                    {!cart ? (
                                        <Button
                                            className='w-full rounded-lg h-9'
                                            onClick={() => {
                                                addToCart({
                                                    id: String(product?.id),
                                                    cover: product.cover,
                                                    name: product?.name,
                                                    qty: 1,
                                                    price: product?.selling_price,
                                                    stock: product?.stock,
                                                });
                                            }}
                                        >
                                            <Plus />
                                            Tambah
                                        </Button>
                                    ) : (
                                        <Button
                                            className='w-full font-semibold rounded-lg h-9'
                                            variant={'outline'}
                                            onClick={() =>
                                                cart?.qty! < cart?.stock!
                                                    ? addToCart({
                                                          id: String(product?.id),
                                                          cover: product.cover,
                                                          name: product?.name,
                                                          qty: 1,
                                                          price: product?.selling_price,
                                                          stock: product?.stock,
                                                      })
                                                    : null
                                            }
                                        >
                                            <Plus />
                                            Tambah Lagi ({cart?.qty})
                                        </Button>
                                    )}
                                </>
                            ) : (
                                <Button className='w-full rounded-lg h-9' variant={'secondary'} disabled>
                                    <X />
                                    Produk Kosong
                                </Button>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
