import { Skeleton } from './ui/skeleton';

export function SkeletonProduct() {
    return (
        <div className='grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 '>
            {Array.from({ length: 6 }).map((_, key) => (
                <div className='flex flex-col p-2 space-y-3 border rounded-lg' key={key}>
                    <Skeleton className='h-[125px] w-full rounded-xl' />
                    <div className='space-y-2'>
                        <Skeleton className='w-full h-4' />
                        <Skeleton className='w-1/2 h-4' />
                    </div>
                </div>
            ))}
        </div>
    );
}

export function SkeletonDetail() {
    return (
        <div className='space-y-2'>
            <div className='flex flex-row justify-between gap-2'>
                <Skeleton className='h-[20px] w-full rounded-xl border' />
                <Skeleton className='h-[20px] w-1/3 rounded-xl border' />
            </div>
            <div className='flex flex-row justify-between gap-2'>
                <Skeleton className='h-[20px] w-full rounded-xl border' />
                <Skeleton className='h-[20px] w-1/4 rounded-xl border' />
            </div>
        </div>
    );
}
