import { Button } from '@/components/ui/button';
import { useCategories, useProductParams } from '@/hooks/use-product';
import { ICategory } from '@/interface/Category';

export default function CategoryList() {
    const { categories, isFetching } = useCategories();
    const { value, setValue } = useProductParams('category');
    return (
        <div className='flex flex-1 gap-2 overflow-x-scroll scrollbar-none'>
            <Button variant={!value ? 'default' : 'outline'} className='h-8 text-sm' onClick={() => setValue('')}>
                SEMUA
            </Button>
            {!isFetching &&
                categories?.map((datum: ICategory, key: number) => (
                    <Button
                        variant={value === String(datum.id) ? 'default' : 'outline'}
                        className='h-8 text-sm'
                        key={key}
                        onClick={() => setValue(String(datum.id))}
                    >
                        {datum?.name.toUpperCase()}
                    </Button>
                ))}
        </div>
    );
}
