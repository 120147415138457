import { ReactNode } from 'react';
// import { Cashier } from '../../components';
import { AppSidebar } from '@/components/app-sidebar';
import { SidebarInset, SidebarProvider, SidebarTrigger } from '@/components/ui/sidebar';
import { Separator } from '@radix-ui/react-separator';
import AppBreadcrumb from '@/components/app-breadcrumb';
// import Search from './search';
import OngoingTransactions from '@/components/ongoing-transaction';

const Layout: React.FC<{ children?: ReactNode }> = ({ children }) => {
    return (
        <SidebarProvider>
            <AppSidebar />
            <SidebarInset>
                <header className='sticky top-0 z-50 mb-2 border-b bg-white flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12'>
                    <div className='flex flex-row items-center justify-between w-full px-4 py-4'>
                        <div className='flex flex-row items-center flex-1 col-span-9 gap-2 2xl:col-span-9 '>
                            <SidebarTrigger className='-ml-1' />
                            <Separator orientation='vertical' className='h-4 mr-2' />
                            <AppBreadcrumb />
                        </div>
                        <OngoingTransactions />
                    </div>
                </header>
                <div className='w-full gap-4 p-4 pt-0 text-sm'>{children}</div>
            </SidebarInset>
        </SidebarProvider>
    );
};

export default Layout;
