import { ChevronsUpDown, ArrowRight } from 'lucide-react';

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuShortcut,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { SidebarMenu, SidebarMenuButton, SidebarMenuItem, useSidebar } from '@/components/ui/sidebar';
import { IMerchant } from '@/interface/Merchant';
import { useMerchantDetail, useMerchants } from '@/hooks/use-merchant';
import { Avatar, AvatarImage } from './ui/avatar';
import { AvatarFallback } from '@radix-ui/react-avatar';
import { getInitialName } from '@/lib/helper';

export function MerchantSwitcher() {
    const { isMobile } = useSidebar();
    const { detailMerchant, handleChooseMerchant } = useMerchantDetail();
    const { merchantData, handleSelectMerchant } = useMerchants();

    return (
        <SidebarMenu>
            <SidebarMenuItem>
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <SidebarMenuButton
                            size='lg'
                            className='data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground'
                        >
                            <div className='flex items-center justify-center rounded-lg aspect-square size-8 bg-sidebar-primary text-sidebar-primary-foreground'>
                                <Avatar className='rounded-sm size-6 shrink-0'>
                                    <AvatarImage src={detailMerchant?.image} />
                                    <AvatarFallback>{getInitialName(detailMerchant?.name)}</AvatarFallback>
                                </Avatar>
                            </div>
                            <div className='grid flex-1 text-sm leading-tight text-left'>
                                <span className='font-semibold truncate'>{detailMerchant?.name}</span>
                                <span className='text-xs truncate'>{detailMerchant?.type}</span>
                            </div>
                            <ChevronsUpDown className='ml-auto' />
                        </SidebarMenuButton>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                        className='w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg'
                        align='start'
                        side={isMobile ? 'bottom' : 'right'}
                        sideOffset={4}
                    >
                        <DropdownMenuLabel className='text-xs text-muted-foreground'>Toko</DropdownMenuLabel>
                        {merchantData.map((merchant: IMerchant, index: number) => (
                            <DropdownMenuItem
                                key={index}
                                onClick={() => handleSelectMerchant(merchant.id)}
                                className='gap-2 p-2'
                            >
                                <div className='flex items-center justify-center border rounded-sm size-6'>
                                    <Avatar className='rounded-sm size-6 shrink-0'>
                                        <AvatarImage src={merchant.image} />
                                        <AvatarFallback>{getInitialName(merchant.name)}</AvatarFallback>
                                    </Avatar>
                                </div>
                                {merchant.name}
                                <DropdownMenuShortcut>⌘{index + 1}</DropdownMenuShortcut>
                            </DropdownMenuItem>
                        ))}
                        <DropdownMenuSeparator />
                        <DropdownMenuItem
                            className='justify-between gap-2 p-2 cursor-pointer'
                            onClick={handleChooseMerchant}
                        >
                            <div className='font-medium text-muted-foreground'>Kelola Toko</div>
                            <div className='flex items-center justify-center border rounded-md size-6 bg-background'>
                                <ArrowRight className='size-4' />
                            </div>
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            </SidebarMenuItem>
        </SidebarMenu>
    );
}
