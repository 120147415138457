import { DatePickerInput } from '@mantine/dates';
import TransactionHooks from './TransactionHooks';
import { DataTable } from '@/components/data-table';
import { columns } from './columns';
import { Button } from '@/components/ui/button';

const Transaction = () => {
    const { params, setParams, transactionData, handleChangeDate } = TransactionHooks();

    return (
        <div className='flex flex-col'>
            <div className='flex justify-between'>
                <div className='py-4 text-xl font-semibold'>Transaksi</div>
            </div>
            <div className='flex flex-col gap-2'>
                <div className='flex flex-col gap-2 bg-white rounded-md md:justify-between md:flex-row'>
                    <div className='flex gap-2'>
                        <Button
                            type='button'
                            size={'sm'}
                            variant={params.status == 'PROCESSING' ? 'default' : 'outline'}
                            onClick={() => setParams({ ...params, status: 'PROCESSING' })}
                        >
                            Diproses
                        </Button>
                        <Button
                            type='button'
                            size={'sm'}
                            variant={params.status == 'COMPLETED' ? 'default' : 'outline'}
                            onClick={() => setParams({ ...params, status: 'COMPLETED' })}
                        >
                            Selesai
                        </Button>
                        <Button
                            type='button'
                            size={'sm'}
                            variant={params.status == 'CANCELLED' ? 'default' : 'outline'}
                            onClick={() => setParams({ ...params, status: 'CANCELLED' })}
                        >
                            Dibatalkan
                        </Button>
                    </div>
                    <div>
                        <DatePickerInput
                            type='range'
                            valueFormat='D MMMM YYYY'
                            placeholder='Tanggal Awal - Tanggal Akhir'
                            onChange={(value: any) => handleChangeDate(value)}
                            miw={250}
                            className='bg-white border-0'
                            clearable
                        />
                    </div>
                </div>
                <DataTable
                    columns={columns}
                    data={transactionData?.data ?? []}
                    current_page={params.page}
                    onChangePage={(value: number) => setParams({ ...params, page: value })}
                    total_page={transactionData?.last_page}
                />
            </div>
        </div>
    );
};

export default Transaction;
