import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import api from '../../../api';
import { products } from '../../../api/endpoints';
import { generatePath } from 'react-router-dom';

function UploadHooks(productId: number) {
    const [modal, setModal] = useState(false);
    const {
        data: images,
        isFetching,
        refetch,
    } = useQuery({
        queryKey: ['product-images', productId],
        queryFn: () => api.get(generatePath(products.images, { id: productId })).then((res) => res.data),
        onSuccess: (data) => data.data.data,
        enabled: !!modal,
    });

    const [image, setImage] = useState('');

    const mutation = useMutation({
        mutationFn: (data: FormData) =>
            api.post(products.upload_image, data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            }),
        onSuccess: () => {
            setImage('');
            refetch();
        },
    });

    const mutationDelete = useMutation({
        mutationFn: (imageId: any) => api.delete(generatePath(products.delete_image, { id: productId, imageId })),
        onSuccess: () => {
            refetch();
        },
    });

    const handleUploadImage = async (value: File) => {
        const data = new FormData();
        data.append('image', value);
        data.append('product_id', String(productId));
        setImage(URL.createObjectURL(value));
        mutation.mutate(data);
    };

    const handleDelete = (imageId: any) => {
        mutationDelete.mutate(imageId);
    };

    return {
        modal,
        setModal,
        images,
        isFetching,
        handleUploadImage,
        image,
        isUploading: mutation.isLoading,
        handleDelete,
    };
}

export default UploadHooks;
