import UserHooks from './UserHooks';
import Create from './create';

import { DataTable } from '@/components/data-table';
import { columns } from './columns';

const User = () => {
    const { user, refetch } = UserHooks();

    return (
        <div className='flex flex-col'>
            <div className='flex justify-between py-2'>
                <div className='text-xl font-semibold'>Karyawan</div>
                <Create refetch={refetch} />
            </div>
            <div className='flex flex-col gap-2'>
                <DataTable data={user?.data ?? []} columns={columns} />
            </div>
        </div>
    );
};

export default User;
