import { ArrowLeft, ArrowRight, ShoppingBag } from 'lucide-react';
import { Button } from './ui/button';
import { useState } from 'react';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from './ui/dialog';
import { useFormOngoingTransaction, useOngoingTransactions } from '@/hooks/use-transaction';
import { IListTransaction } from '@/interface/Transaction';
import { getInitialName } from '@/lib/helper';
import DetailTransaction from './detail-transaction';

export default function OngoingTransactions() {
    const [open, setOpen] = useState(false);
    const { transactions } = useOngoingTransactions();
    const {
        detailTransaction,
        setDetailTransaction,
        control,
        errors,
        watch,
        cart,
        handleSubmit,
        setIsPay,
        isPay,
        processing,
        isFetchingDetail,
        isCancelling,
        handleCancelTransaction,
    } = useFormOngoingTransaction();

    return (
        <div>
            {transactions.length > 0 && (
                <Button className='relative h-8' onClick={() => setOpen(!open)}>
                    <ShoppingBag />
                    Transaksi Berjalan
                    <div className='flex items-center justify-center w-5 h-5 text-xs text-white bg-red-500 rounded-full '>
                        {transactions.length}
                    </div>
                </Button>
            )}
            <Dialog
                open={open}
                onOpenChange={() => {
                    setOpen(!open);
                    setDetailTransaction(null);
                }}
            >
                <DialogContent className='gap-2'>
                    <DialogHeader>
                        <DialogTitle>
                            {detailTransaction && (
                                <Button variant={'outline'} onClick={() => setDetailTransaction(null)} className='mr-2'>
                                    <ArrowLeft />
                                </Button>
                            )}
                            {detailTransaction ? 'Detail Transaksi' : 'Transaksi Berjalan'}
                        </DialogTitle>
                        <DialogDescription>
                            Berikut adalah{' '}
                            {detailTransaction
                                ? 'detail transaksi anda'
                                : 'daftartransaksi yang sedang berjalan/belum dibayar'}
                            . Silakan selesaikan pembayaran untuk menyelesaikan transaksi.
                        </DialogDescription>
                    </DialogHeader>

                    {!detailTransaction &&
                        transactions.map((transaction: IListTransaction) => {
                            return (
                                <div key={transaction.id} className='flex flex-row gap-2 p-2 border rounded-md'>
                                    <span className='w-8 h-8 py-1 text-center text-white align-middle rounded-md bg-destructive'>
                                        {getInitialName(transaction.customer_name)}
                                    </span>
                                    <div className='flex-1'>
                                        <div className='text-sm font-semibold'>{transaction.customer_name || '-'}</div>
                                        <div className='text-xs'>#{transaction.code}</div>
                                    </div>
                                    <Button
                                        className='h-8 rounded-lg'
                                        onClick={() => setDetailTransaction(transaction)}
                                    >
                                        Bayar <ArrowRight />{' '}
                                    </Button>
                                </div>
                            );
                        })}

                    <DetailTransaction
                        control={control}
                        cart={cart}
                        detailTransaction={detailTransaction}
                        errors={errors}
                        handleCancelTransaction={handleCancelTransaction}
                        handleSubmit={handleSubmit}
                        isCancelling={isCancelling}
                        isFetchingDetail={isFetchingDetail}
                        isPay={isPay}
                        processing={processing}
                        setDetailTransaction={setDetailTransaction}
                        setIsPay={setIsPay}
                        watch={watch}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
}
