import api from '../../../api';
import { FieldValues, useForm } from 'react-hook-form';
import { IProduct } from '../../../interface/Product';
import { product_category, products } from '../../../api/endpoints';
import { useQuery } from '@tanstack/react-query';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import { generatePath } from 'react-router-dom';

function FormHooks(_data?: IProduct, refetch?: () => void, handleClose?: () => void) {
    const {
        register,
        control,
        formState: { errors },
        watch,
        handleSubmit,
    } = useForm({
        defaultValues: {
            name: _data ? _data.name : '',
            buying_price: _data ? _data.buying_price : undefined,
            selling_price: _data ? _data.selling_price : undefined,
            stock: _data ? _data.stock : undefined,
            category_ids: _data?.categories ? _data?.categories?.map((val) => String(val.id)) : [],
            sku: _data ? _data.sku : '',
            description: _data ? _data.description : '',
            unit: _data ? _data.unit : '',
        },
    });
    const { data: productCategory = [] } = useQuery(['product_category_form'], async () => {
        const res = await api.get(product_category.fetch, {
            params: {
                merchant_id: localStorage.getItem('merchantID'),
            },
        });

        if (!res?.data?.status) {
            notifications.show({
                message: 'Ups, terjadi kesalahan saat fetch kategori produk.',
                color: 'red',
            });
        }

        const convertData: Array<{ label: string; value: string }> = res?.data?.data?.map(
            (each: { name: string; id: number }) => {
                return { label: each.name, value: String(each.id) };
            }
        );
        return convertData;
    });
    const [loading, setLoading] = useState(false);

    const onSubmit = async (data: FieldValues) => {
        const payload = {
            ...data,
            category_ids: data.category_ids.map((val: any) => Number(val)),
            discount: 0,
            merchant_id: Number(localStorage.getItem('merchantID')),
        };

        setLoading(true);
        const url = _data ? generatePath(products.update, { id: _data?.id }) : products.create;
        const res = _data ? await api.put(url, payload) : await api.post(url, payload);
        console.log(res);
        setLoading(false);

        if (res.data.status) {
            refetch && refetch();
            handleClose && handleClose();
        } else {
            notifications.show({
                message: 'Ups, terjadi kesalahan saat submit merchant.',
                color: 'red',
            });
        }
    };

    return {
        register,
        control,
        errors,
        watch,
        handleSubmit,
        productCategory,
        loading,
        onSubmit,
    };
}

export default FormHooks;
