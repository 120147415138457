import axios, { InternalAxiosRequestConfig } from 'axios';

const axiosInstance = axios.create();

// setup base url and setup request
axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem('token');
    config.headers = config.headers || {};
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    config.baseURL = import.meta.env.VITE_APP_BASE_API_URL;

    return config;
});

axiosInstance.interceptors.response.use(
    async function (response) {
        if (response.data) {
            // return success
            if (response.status === 200 || response.status === 201) {
                return response;
            }
            // reject errors & warnings
            return Promise.resolve(response);
        }

        // default fallback
        return Promise.resolve(response);
    },
    async function (error) {
        // if the server throws an error (404, 500 etc.)
        return Promise.resolve(error?.response);
    }
);

const api = axiosInstance;

export default api;
