import api from '@/api';
import { transaction } from '@/api/endpoints';
import { IListTransaction } from '@/interface/Transaction';
import { notifications } from '@mantine/notifications';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { generatePath } from 'react-router-dom';

export function useOngoingTransactions() {
    const merchantID = localStorage.getItem('merchantID');
    const { data, isFetching } = useQuery({
        queryKey: ['ongoing_transaction_cashier', merchantID],
        queryFn: async () => {
            const params: any = {
                merchant_id: merchantID,
                page: 1,
                per_page: 100,
                status: 'PROCESSING',
            };

            return api
                .get(transaction.fetch, {
                    params,
                })
                .then((res) => res.data);
        },
    });

    return {
        transactions: data ? data.data : [],
        isFetching,
    };
}

export type UseFormOngoingTransactionHook = ReturnType<typeof useFormOngoingTransaction>;

export function useFormOngoingTransaction() {
    const queryClient = useQueryClient();
    const [detailTransaction, setDetailTransaction] = useState<IListTransaction | null>(null);
    const [isPay, setIsPay] = useState(false);

    const {
        control,
        watch,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        defaultValues: {
            customer_name: '',
            discount: 0,
            total_payment: 0,
        },
    });

    const { isFetching, data } = useQuery({
        queryKey: ['cashier_transaction_detail', detailTransaction?.code],
        queryFn: async () => {
            const url = generatePath(transaction.detail, { code: detailTransaction?.code });
            return api.get(url).then((res) => res.data);
        },
        enabled: !!detailTransaction?.code,
    });

    const mutation = useMutation({
        mutationFn: (data: any) => api.post(transaction.accept, data),
        onSuccess: () => {
            reset();
            setIsPay(false);
            setDetailTransaction(null);
            queryClient.invalidateQueries(['ongoing_transaction_cashier']);
            notifications.show({
                title: 'Transaksi Selesai',
                message: 'Yei, pesanan berhasil diselesaikan!',
                color: 'green',
            });
        },
    });

    const cancellMutation = useMutation({
        mutationFn: (data: any) => api.post(transaction.cancel, data),
        onSuccess: () => {
            reset();
            setIsPay(false);
            setDetailTransaction(null);
            queryClient.invalidateQueries(['ongoing_transaction_cashier']);
            notifications.show({
                title: 'Transaksi Dibatalkan!',
                message: 'Transaksi berhasil dibatalkan.',
                color: 'green',
            });
        },
    });

    const handleSubmitTransaction = async (data: FieldValues) => {
        const payload = {
            discount: data.discount,
            total_payment: data?.total_payment,
            transaction_code: detailTransaction?.code,
        };
        mutation.mutate(payload);
    };

    const handleCancelTransaction = async () => {
        const payload = {
            transaction_code: detailTransaction?.code,
        };

        cancellMutation.mutate(payload);
    };

    return {
        control,
        watch,
        errors,
        handleSubmit: handleSubmit(handleSubmitTransaction),
        detailTransaction,
        setDetailTransaction,
        cart: data ? data.data.items : [],
        isPay,
        setIsPay: () => setIsPay(!isPay),
        processing: mutation.isLoading,
        isFetchingDetail: isFetching,
        handleCancelTransaction,
        isCancelling: cancellMutation.isLoading,
    };
}
