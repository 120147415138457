import { Button, Modal } from '@mantine/core';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import DeleteHooks from './DeleteHooks';

type Props = {
    id: number;
    name: string;
};

const Delete: React.FC<Props> = (props) => {
    const { id, name } = props;

    const { modal, setModal, loading, handleDelete } = DeleteHooks();

    return (
        <div>
            <div
                aria-hidden
                className='text-red-600 cursor-pointer w-fit hover:text-red-700'
                onClick={() => setModal(true)}
            >
                Hapus
            </div>

            <Modal
                opened={modal}
                onClose={() => setModal(false)}
                withCloseButton={false}
                radius='lg'
                size={350}
                padding={30}
                centered
            >
                <MdOutlineDeleteOutline className='mx-auto mb-1 text-red-600 text-8xl' />
                <div className='text-base font-semibold text-center'>Anda yakin akan menghapus {name}?</div>
                <div className='grid grid-cols-2 gap-3 pt-5'>
                    <Button variant='outline' onClick={() => setModal(false)}>
                        Batal
                    </Button>
                    <Button color='red' className='bg-red-600' loading={loading} onClick={() => handleDelete(id)}>
                        Hapus
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default Delete;
