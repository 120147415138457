import React, { ChangeEvent } from 'react';
import { Image } from '@mantine/core';
import { BsUpload } from 'react-icons/bs';
import { MdOutlineImageNotSupported } from 'react-icons/md';

type Props = {
    src: string;
    accept: string;
    handleChange: (value: File) => void;
};

const UploadImage: React.FC<Props> = (props) => {
    const { src, accept, handleChange } = props;

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const file = e?.target?.files?.[0];
        if (file) {
            handleChange(file);
        }
    };

    return (
        <div className='relative min-w-[100px] flex items-center border border-gray-400 border-dashed rounded'>
            {src !== '' ? (
                <Image
                    src={src}
                    width='100%'
                    height={114}
                    withPlaceholder
                    placeholder={<MdOutlineImageNotSupported className='text-5xl' />}
                />
            ) : (
                <div className='w-full py-6 text-xs text-gray-500'>
                    <BsUpload className='mb-1 ml-auto mr-auto text-lg' />
                    <div className='text-xs text-center'>Upload Gambar</div>
                </div>
            )}
            <input
                type='file'
                accept={accept}
                className='absolute top-0 bottom-0 left-0 right-0 z-10 opacity-0 cursor-pointer'
                onChange={onChange}
            />
        </div>
    );
};

export default UploadImage;
