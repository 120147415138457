import OrderList from '@/pages/cashier/order-list';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader } from '@/components/ui/card';
import { Drawer, DrawerContent, DrawerDescription, DrawerTitle } from '@/components/ui/drawer';
import { Separator } from '@/components/ui/separator';
import { useCart, useCartProcess } from '@/hooks/use-cart';
import { formatRupiah } from '@/lib/helper';
import { Input, NumberInput } from '@mantine/core';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Loader } from 'lucide-react';

export default function ShopingCart() {
    const merchantID = localStorage.getItem('merchantID')!;
    const { data } = useCart(merchantID);
    const { activeTab, isDirectPay, setIsDirectPay, watch, errors, register, control, handleSubmit, isProcessing } =
        useCartProcess();
    const [open, setOpen] = useState(false);

    return (
        <>
            <Card className='h-[calc(100vh-6rem)] flex flex-col bg-muted/40 border-0 md:border'>
                <CardHeader className='p-2'>Ringkasan Pesanan</CardHeader>
                <Separator />
                <CardContent className='flex-1 p-2 overflow-y-scroll '>
                    <OrderList cart={data?.cart ?? []} />
                </CardContent>
                <CardFooter className='flex-col items-end w-full gap-2 p-2 '>
                    <Card className='w-full'>
                        <CardContent className='p-2 space-y-2 '>
                            <div className='font-semibold '>Rincian Transaksi</div>
                            <Separator />
                            <div className='flex flex-col gap-1 '>
                                <div className='flex justify-between font-medium'>
                                    <div>Sub Total</div>
                                    <div className='font-semibold'>{formatRupiah(data?.totalPayment ?? 0)}</div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                    <div className='hidden w-full md:block'>
                        <TransactionForm
                            isDirectPay={isDirectPay}
                            setIsDirectPay={setIsDirectPay}
                            watch={watch}
                            errors={errors}
                            register={register}
                            control={control}
                            activeTab={activeTab}
                            data={data}
                        />
                    </div>
                    <Card className='hidden w-full md:block'>
                        <CardContent className='p-2 space-y-2 '>
                            <div className='flex justify-between'>
                                <div>Diskon</div>
                                <div className='font-semibold'>{formatRupiah(watch('discount'))}</div>
                            </div>
                            <div className='flex justify-between'>
                                <div>Kembali</div>
                                <div className='font-semibold'>
                                    {formatRupiah(
                                        (watch('total_payment') ?? 0) - (data?.totalPayment! - (watch('discount') ?? 0))
                                    )}
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                    <Button className='block w-full md:hidden h-9 rounded-xl' onClick={() => setOpen(true)}>
                        {activeTab === '' ? 'Proses' : 'Perbaharui'} Transaksi
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        className='hidden w-full h-9 rounded-xl md:flex'
                        disabled={data?.cart.length === 0 || isProcessing}
                    >
                        {isProcessing ? <Loader /> : null}
                        {activeTab === '' ? 'Proses' : 'Perbaharui'} Transaksi
                    </Button>
                </CardFooter>
            </Card>
            <Drawer open={open} onOpenChange={setOpen}>
                <DrawerContent className='w-full [&>button:last-child]:hidden outline-none p-0'>
                    <DrawerTitle />
                    <DrawerDescription className='m-2'>
                        <TransactionForm
                            isDirectPay={isDirectPay}
                            setIsDirectPay={setIsDirectPay}
                            watch={watch}
                            errors={errors}
                            register={register}
                            control={control}
                            activeTab={activeTab}
                            data={data}
                        />
                        <div className='block mt-2 md:hidden'>
                            <Button
                                onClick={handleSubmit}
                                className='w-full h-9 rounded-xl'
                                disabled={data?.cart.length === 0}
                            >
                                Buat Pesanan
                            </Button>
                        </div>
                    </DrawerDescription>
                </DrawerContent>
            </Drawer>
        </>
    );
}

const TransactionForm = ({ isDirectPay, setIsDirectPay, data, activeTab, errors, control, watch }: any) => {
    return (
        <Card className='w-full '>
            <CardContent className='p-2 space-y-2 '>
                <div className='flex flex-row gap-2'>
                    <Button
                        className='flex-1 h-8 rounded-xl'
                        variant={isDirectPay ? 'default' : 'outline'}
                        onClick={() => setIsDirectPay(true)}
                        disabled={data?.cart.length === 0}
                    >
                        Bayar Lansung
                    </Button>
                    <Button
                        className='flex-1 h-8 rounded-xl'
                        variant={!isDirectPay ? 'default' : 'outline'}
                        onClick={() => setIsDirectPay(false)}
                        disabled={data?.cart.length === 0}
                    >
                        Bayar Nanti
                    </Button>
                </div>
                <Separator />
                {activeTab === '' && (
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='font-medium '>Nama Pelanggan</div>
                        <Controller
                            name='customer_name'
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Nama konsumen wajib diisi!',
                                },
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <div>
                                    <Input {...field} placeholder='Nama Konsumen' />
                                    {error && <p className='mt-1 text-sm text-red-500'>{error.message}</p>}
                                </div>
                            )}
                        />
                    </div>
                )}
                <div className='grid grid-cols-2 gap-2'>
                    <div className='font-medium '>Potongan Harga</div>
                    <Controller
                        name='discount'
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Wajib diisi!',
                            },
                        }}
                        render={({ field }) => (
                            <NumberInput
                                {...field}
                                icon={<div className='pl-2 text-sm text-black'>Rp</div>}
                                hideControls
                                defaultValue={0}
                                placeholder='0'
                                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                formatter={(value) => {
                                    const numb = value && value[0] === '0' && value.length > 1 ? value.slice(1) : value;

                                    return numb && !Number.isNaN(parseFloat(numb))
                                        ? parseFloat(numb).toLocaleString('en')
                                        : '';
                                }}
                                size='sm'
                                disabled={data?.cart.length === 0}
                                error={errors?.discount?.message ? String(errors?.discount?.message) : null}
                            />
                        )}
                    />
                </div>
                {isDirectPay && (
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='font-medium '>Total Pembayaran</div>
                        <Controller
                            name='total_payment'
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Wajib diisi!',
                                },
                                min: {
                                    value: Number(data?.totalPayment ?? 0) - (watch('discount') ?? 0),
                                    message: 'Kurang dari total!',
                                },
                            }}
                            render={({ field }) => (
                                <NumberInput
                                    {...field}
                                    icon={<div className='pl-2 text-sm text-black'>Rp</div>}
                                    hideControls
                                    defaultValue={0}
                                    placeholder='0'
                                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                    formatter={(value) => {
                                        const numb =
                                            value && value[0] === '0' && value.length > 1 ? value.slice(1) : value;

                                        return numb && !Number.isNaN(parseFloat(numb))
                                            ? parseFloat(numb).toLocaleString('en')
                                            : '';
                                    }}
                                    size='sm'
                                    disabled={data?.cart.length === 0}
                                    error={
                                        errors?.total_payment?.message ? String(errors?.total_payment?.message) : null
                                    }
                                />
                            )}
                        />
                    </div>
                )}
            </CardContent>
        </Card>
    );
};
