import React from 'react';
import { Drawer, DrawerContent, DrawerDescription, DrawerTitle, DrawerTrigger } from '@/components/ui/drawer';
import { useMediaQuery } from '@/hooks/use-media-query';
import ShopingCart from './shoping-cart';

export default function CartMobile({ children }: { children: React.ReactNode }) {
    const [open, setOpen] = React.useState(false);
    const isDesktop = useMediaQuery('(min-width: 768px)');

    const setClosed = React.useCallback(() => {
        if (isDesktop) {
            setOpen(false);
        }
    }, [setOpen, isDesktop]);

    React.useEffect(() => {
        setClosed();
    }, [setClosed]);

    // if (isTablet) {
    //     return (
    //         <Dialog open={open} onOpenChange={setOpen}>
    //             <DialogTrigger asChild>{children}</DialogTrigger>
    //             <DialogContent className='sm:max-w-[425px] [&>button:last-child]:hidden outline-none p-0 '>
    //                 <DialogTitle />
    //                 <ShopingCart />
    //             </DialogContent>
    //         </Dialog>
    //     );
    // }

    return (
        <Drawer open={open} onOpenChange={setOpen}>
            <DrawerTrigger asChild>{children}</DrawerTrigger>
            <DrawerContent className='outline-none '>
                <DrawerTitle />
                <div>
                    <ShopingCart />
                </div>
                <DrawerDescription />
            </DrawerContent>
        </Drawer>
    );
}
