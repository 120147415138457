import { TransactionStatusBadge } from '@/components';
import { IListTransaction } from '@/interface/Transaction';
import { formatRupiah } from '@/lib/helper';
import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';
import Detail from './detail';
// import UploadImages from './uploadImages';

export const columns: ColumnDef<IListTransaction>[] = [
    {
        header: 'Kode Transaksi',
        cell: ({ row }) => {
            const data = row.original;
            return (
                <div>
                    <div className='mb-1 font-semibold'>{data?.code}</div>
                    <div className='text-xs text-gray-500'>dibuat oleh {data?.created_by?.name ?? '-'}</div>
                </div>
            );
        },
    },
    {
        accessorKey: 'total_item',
        header: 'Total Item',
    },
    {
        header: 'Transaksi',
        cell: ({ row }) => {
            const data = row.original;
            return (
                <div className='flex flex-col'>
                    <div className='mb-1 text-xs text-end md:text-start md:text-sm'>
                        Total Harga: <span className='font-medium'> {formatRupiah(data?.total_price)}</span>
                    </div>
                    <div className='mb-1 text-xs text-red-600 text-end md:text-start md:text-sm'>
                        Diskon: <span className='font-medium'>Rp {formatRupiah(data?.discount)}</span>
                    </div>
                    <div className='mb-1 text-xs text-end md:text-start md:text-sm'>
                        Jumlah Pembayaran: <span className='font-medium'>Rp {formatRupiah(data?.total_payment)}</span>
                    </div>
                </div>
            );
        },
    },
    {
        accessorKey: 'created_at',
        header: 'Total Item',
        cell: ({ row }) => {
            return <>{dayjs(row?.getValue('created_at')).format('D MMMM YYYY, HH:mm')}</>;
        },
    },
    {
        accessorKey: 'customer_name',
        header: 'Customer',
    },
    {
        accessorKey: 'status',
        header: 'Status',
        cell: ({ row }) => {
            return <TransactionStatusBadge status={row.getValue('status')} />;
        },
    },
    {
        id: 'actions',
        cell: ({ row }) => {
            const data = row.original;
            return <Detail data={data} />;
        },
    },
];
