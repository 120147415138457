/* eslint-disable  @typescript-eslint/no-explicit-any */
import api from '@/api';
import { transaction } from '@/api/endpoints';
import { notifications } from '@mantine/notifications';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

export interface CartItem {
    id: string;
    name: string;
    price: number;
    qty: number;
    cover: { url: string } | null;
    stock: number;
}
interface CartWithTotal {
    cart: CartItem[];
    totalPayment: number;
}

const initialCart: CartItem[] = [];

export const useCart = (merchant: string) => {
    return useQuery<CartItem[], Error, CartWithTotal>({
        queryKey: [merchant],
        queryFn: () => {
            if (typeof window === 'undefined') return initialCart;
            const storedCart = localStorage.getItem(merchant);
            const cart = storedCart ? JSON.parse(storedCart) : initialCart;
            return cart || initialCart;
        },
        initialData: () => {
            const storedCart = localStorage.getItem(merchant);
            return storedCart ? JSON.parse(storedCart) : initialCart;
        },
        select: (cart: CartItem[]) => {
            const totalPayment = cart.reduce((total, item) => {
                return total + item.price * item.qty; // Menghitung total pembayaran
            }, 0);

            return { cart, totalPayment }; // Mengembalikan cart dan total pembayaran
        },
    });
};

// Hook untuk menambah item ke cart
export const useAddToCart = (merchant: string) => {
    const queryClient = useQueryClient();

    return useMutation<CartItem[], unknown, CartItem>({
        mutationFn: () => {
            // mutationFn hanya me-return current state (tidak ada update di sini)
            const currentCart = queryClient.getQueryData<CartItem[]>([merchant]) || [];
            return Promise.resolve(currentCart);
        },
        onMutate: async (newItem) => {
            console.log('on Mutate');

            const currentCart = queryClient.getQueryData<CartItem[]>([merchant]) || [];
            const existingItem = currentCart.find((i) => i.id === newItem.id);

            let optimisticCart: CartItem[];
            if (existingItem) {
                optimisticCart = currentCart.map((i) => (i.id === newItem.id ? { ...i, qty: i.qty + 1 } : i));
            } else {
                optimisticCart = [...currentCart, { ...newItem, qty: 1 }];
            }

            // Optimistic update
            queryClient.setQueryData([merchant], optimisticCart);
            return { previousCart: currentCart }; // Simpan state lama untuk rollback
        },
        onError: (_error, _itemId, context?: any) => {
            console.log('on Error');
            if (context?.previousCart) {
                queryClient.setQueryData([merchant], context.previousCart);
            }
        },
        onSuccess: (data) => {
            // Simpan cart ke LocalStorage
            localStorage.setItem(merchant, JSON.stringify(data));
        },
    });
};

// Hook untuk menghapus item dari cart
export const useRemoveFromCart = (merchant: string) => {
    const queryClient = useQueryClient();

    return useMutation<CartItem[], unknown, string>({
        mutationFn: () => {
            const currentCart = queryClient.getQueryData<CartItem[]>([merchant]) || [];
            return Promise.resolve(currentCart);
        },
        onMutate: async (itemId) => {
            const currentCart = queryClient.getQueryData<CartItem[]>([merchant]) || [];

            // Buat optimisticCart dengan penanganan aman
            const optimisticCart = currentCart
                .map((item) => (item.id === itemId ? { ...item, qty: item.qty > 1 ? item.qty - 1 : 0 } : item))
                .filter((item) => item.qty > 0); // Hapus item jika qty = 0

            // Set data secara optimistik
            queryClient.setQueryData([merchant], optimisticCart);

            return { previousCart: currentCart };
        },
        onError: (_error, _itemId, context: any) => {
            // Rollback ke state sebelumnya jika terjadi error
            if (context?.previousCart) {
                queryClient.setQueryData([merchant], context.previousCart);
            }
        },
        onSuccess: (data) => {
            // Simpan data terbaru ke localStorage
            localStorage.setItem(merchant, JSON.stringify(data));
        },
    });
};

// Hook untuk mengosongkan cart
export const useClearCart = (key: string) => {
    const queryClient = useQueryClient();

    return useMutation<CartItem[]>({
        mutationFn: () => Promise.resolve([]),
        onMutate: async () => {
            // Optimistic update: Kosongkan cart di state
            queryClient.setQueryData([key], []);
        },
        onError: (_error, _, context: any) => {
            if (context?.previousCart) {
                // Rollback ke state sebelumnya jika terjadi error
                queryClient.setQueryData([key], context.previousCart);
            }
        },
        onSuccess: () => {
            // Hapus cart dari LocalStorage
            localStorage.removeItem(key);
        },
    });
};

export const useCartProcess = () => {
    const queryClient = useQueryClient();
    const [isDirectPay, setIsDirectPay] = useState<Boolean>(true);
    const [activeTab, setActiveTab] = useState<string>('');
    const { data: dataCart } = useCart(localStorage.getItem('merchantID')!);
    const { mutate: clearCart } = useClearCart(localStorage.getItem('merchantID')!);

    const {
        control,
        register,
        watch,
        formState: { errors },
        handleSubmit,
        setValue,
        reset,
    } = useForm({
        defaultValues: {
            customer_name: '',
            discount: 0,
            total_payment: 0,
        },
    });

    const mutation = useMutation({
        mutationFn: (data: any) =>
            activeTab === '' ? api.post(transaction.create, data) : api.post(transaction.update_item, data),
        onSuccess: () => {
            clearCart();
            reset();
            queryClient.invalidateQueries(['ongoing_transaction_cashier']);
            queryClient.invalidateQueries(['products']);
            notifications.show({
                title: 'Success',
                message: 'Yei, pesanan berhasil dibuat!',
            });
        },
    });

    const handleSubmitTransaction = async (data: FieldValues) => {
        const payload = {
            merchant_id: parseInt(localStorage.getItem('merchantID') || '0'),
            discount: data.discount,
            total_payment: data?.total_payment,
            total_price: dataCart?.totalPayment,
            customer_name: data.customer_name,
            items: dataCart?.cart?.map((each) => {
                return { product_id: each.id, qty: each.qty };
            }),
            transaction_code: activeTab,
        };

        mutation.mutate(payload);
    };

    return {
        isDirectPay,
        setIsDirectPay,
        activeTab,
        control,
        register,
        watch,
        errors,
        setValue,
        setActiveTab,
        handleSubmit: handleSubmit(handleSubmitTransaction),
        isProcessing: mutation.isLoading,
    };
};
