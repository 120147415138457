import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Link, Navigate } from 'react-router-dom';
import { Card, CardContent } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import LoginHooks from './LoginHooks';
import packageJson from '../../../package.json';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Loader2 } from 'lucide-react';

export default function Login({ className, ...props }: React.ComponentProps<'div'>) {
    const { loading, handleLogin, ...form } = LoginHooks();

    if (localStorage.getItem('token') !== null) {
        if (localStorage.getItem('merchantID') === null) {
            return <Navigate to='/merchant' replace />;
        }

        return <Navigate to='/app/dashboard' replace />;
    }
    return (
        <div className='flex flex-col items-center justify-center p-6 min-h-svh bg-muted md:p-10'>
            <div className='w-full max-w-sm md:max-w-3xl'>
                <div className={cn('flex flex-col gap-6', className)} {...props}>
                    <Card className='overflow-hidden'>
                        <CardContent className='grid p-0 md:grid-cols-2'>
                            <Form {...form}>
                                <form className='p-6 md:p-8' onSubmit={handleLogin}>
                                    <div className='flex flex-col gap-6'>
                                        <div className='flex flex-col items-center text-center'>
                                            <h1 className='text-2xl font-bold'>Selamat Datang</h1>
                                            <p className='text-balance text-muted-foreground'>
                                                Masuk ke akun Kangkasir Anda
                                            </p>
                                        </div>
                                        <div className='grid gap-2'>
                                            <FormField
                                                control={form.control}
                                                name='email'
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <FormLabel>Email Address</FormLabel>
                                                        <FormControl>
                                                            <Input
                                                                id='email'
                                                                type='email'
                                                                placeholder='m@example.com'
                                                                required
                                                                {...field}
                                                            />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )}
                                            />
                                        </div>
                                        <div className='grid gap-2'>
                                            <FormField
                                                control={form.control}
                                                name='password'
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <FormLabel>Password</FormLabel>
                                                        <FormControl>
                                                            <Input
                                                                id='password'
                                                                type='password'
                                                                placeholder='*******'
                                                                required
                                                                {...field}
                                                            />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )}
                                            />
                                        </div>
                                        <Button type='submit' className='w-full' disabled={loading}>
                                            {loading && <Loader2 className='animate-spin' />}
                                            Masuk
                                        </Button>

                                        <div className='text-sm text-center'>
                                            Belum memiliki akun?{' '}
                                            <Link
                                                className='text-blue-700 cursor-pointer hover:underline'
                                                to='/register?segment=register'
                                            >
                                                Daftar disini
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                            </Form>
                            <div className='relative hidden bg-muted md:block'>
                                <img
                                    src='/illus_auth.svg'
                                    alt='authentication illustration'
                                    className='absolute inset-0 h-full w-full object-cover dark:brightness-[0.2] dark:grayscale'
                                />
                            </div>
                        </CardContent>
                    </Card>
                    <div className='text-balance text-center text-xs text-muted-foreground [&_a]:underline [&_a]:underline-offset-4 hover:[&_a]:text-primary'>
                        {/* By clicking continue, you agree to our <a href='#'>Terms of Service</a> and{' '}
                        <a href='#'>Privacy Policy</a>. */}
                        Web version {packageJson.version}
                    </div>
                </div>
            </div>
        </div>
    );
}
