import { useLocation } from 'react-router-dom';
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from './ui/breadcrumb';
import useMenus from '@/hooks/use-menus';
import React from 'react';

export default function AppBreadcrumb() {
    const location = useLocation();
    const { menus } = useMenus();
    const { pathname } = location;

    const pathSegments = pathname.split('/').filter(Boolean);

    const breadcrumbs = pathSegments.map((segment, index) => {
        const path = `/${pathSegments.slice(0, index + 1).join('/')}`;

        const route = menus.find((route) => {
            // Replace dynamic segments (e.g., :id) with the current segment
            const pathRegex = new RegExp(route.url.replace(/:\w+/g, '[^/]+'));
            return pathRegex.test(path);
        });

        return {
            path,
            label: route?.name || segment,
        };
    });

    return (
        <Breadcrumb>
            <BreadcrumbList>
                <BreadcrumbItem>
                    <BreadcrumbPage>Home</BreadcrumbPage>
                </BreadcrumbItem>
                {breadcrumbs.map((breadcrumb, key) => {
                    if (breadcrumb.label == 'app') {
                        return null;
                    }
                    return (
                        <React.Fragment key={key}>
                            <BreadcrumbSeparator />
                            <BreadcrumbItem>
                                <BreadcrumbLink href={breadcrumb.path}>{breadcrumb.label}</BreadcrumbLink>
                            </BreadcrumbItem>
                        </React.Fragment>
                    );
                })}
            </BreadcrumbList>
        </Breadcrumb>
    );
}
