import { Button, Input, Select } from '@mantine/core';
import { Link } from 'react-router-dom';
import FormHooks from './FormHooks';
import { Controller } from 'react-hook-form';
import { UploadImage } from '../../../components';

const Form = () => {
    const { register, control, watch, errors, handleSubmit, loading, handleUploadImage, onSubmit } = FormHooks();

    return (
        <div className='p-4 bg-white rounded-lg'>
            <form className='grid w-full grid-cols-1 gap-2 ' onSubmit={handleSubmit(onSubmit)}>
                <div className='flex justify-center'>
                    <UploadImage
                        src={watch('image')}
                        accept='image/jpg,image/jpeg,image/png'
                        handleChange={handleUploadImage}
                    />
                </div>
                <Input.Wrapper
                    label='Nama Toko'
                    required
                    error={errors?.name?.message ? String(errors?.name?.message) : null}
                >
                    <Input
                        {...register('name', {
                            required: {
                                value: true,
                                message: 'Nama toko wajib diisi',
                            },
                        })}
                        placeholder='input nama toko'
                        error={errors?.name?.message ? String(errors?.name?.message) : null}
                    />
                </Input.Wrapper>
                <Input.Wrapper
                    label='Alamat Toko'
                    required
                    error={errors?.address?.message ? String(errors?.address?.message) : null}
                >
                    <Input
                        {...register('address', {
                            required: {
                                value: true,
                                message: 'Alamat toko wajib diisi',
                            },
                        })}
                        placeholder='input alamat toko'
                        error={errors?.address?.message ? String(errors?.address?.message) : null}
                    />
                </Input.Wrapper>
                <Input.Wrapper
                    label='Deskripsi Toko'
                    required
                    error={errors?.description?.message ? String(errors?.description?.message) : null}
                >
                    <Input
                        {...register('description', {
                            required: {
                                value: true,
                                message: 'Deskripsi toko wajib diisi',
                            },
                        })}
                        placeholder='input deskripsi toko'
                        error={errors?.description?.message ? String(errors?.description?.message) : null}
                    />
                </Input.Wrapper>
                <Input.Wrapper
                    label='Nomor Whatsapp'
                    required
                    error={errors?.phone_number?.message ? String(errors?.phone_number?.message) : null}
                >
                    <Input
                        icon={<div className='text-sm text-black'>+62</div>}
                        {...register('phone_number', {
                            required: {
                                value: true,
                                message: 'Nomor whatsapp wajib diisi',
                            },
                        })}
                        placeholder='exp: 812345678'
                        error={errors?.phone_number?.message ? String(errors?.phone_number?.message) : null}
                    />
                </Input.Wrapper>
                <Controller
                    name='type'
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'Tipe merchant wajib diisi',
                        },
                    }}
                    render={({ field }) => (
                        <Select
                            {...field}
                            label='Tipe Merchant'
                            placeholder='pilih tipe merchant'
                            data={[
                                { value: 'JASA', label: 'JASA' },
                                { value: 'OUTLET', label: 'OUTLET' },
                                { value: 'RESTAURANT', label: 'RESTAURANT' },
                            ]}
                            required
                            error={errors?.type?.message ? String(errors?.type?.message) : null}
                        />
                    )}
                />
                <div className='flex justify-end gap-2 mt-5'>
                    <Link to='/merchant'>
                        <Button variant='outline' color='red'>
                            Batal
                        </Button>
                    </Link>
                    <Button type='submit' className='bg-blue-900' loading={loading}>
                        Submit
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default Form;
