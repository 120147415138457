import {
    Pagination,
    PaginationContent,
    PaginationEllipsis,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious,
} from '@/components/ui/pagination';

export function AppPagination({
    total_page,
    onChangePage,
    current_page,
}: {
    total_page: number;
    onChangePage: (val: number) => void;
    current_page?: number;
}) {
    const currentPage = current_page ?? 1;

    const handlePageChange = (page: number) => {
        onChangePage(page);
    };

    const renderPaginationItems = () => {
        const items = [];
        const maxVisiblePages = 5;

        // Jika total halaman <= maxVisiblePages, tampilkan semua
        if (total_page <= maxVisiblePages) {
            for (let i = 1; i <= total_page; i++) {
                items.push(
                    <PaginationItem key={i}>
                        <PaginationLink
                            href='#'
                            className='h-8'
                            isActive={i === currentPage}
                            onClick={(e) => {
                                e.preventDefault();
                                handlePageChange(i);
                            }}
                        >
                            {i}
                        </PaginationLink>
                    </PaginationItem>
                );
            }
        } else {
            // Jika halaman terlalu banyak, gunakan ellipsis
            let startPage = Math.max(1, currentPage - 2);
            let endPage = Math.min(total_page, currentPage + 2);

            // Tambahkan halaman pertama
            if (startPage > 1) {
                items.push(
                    <PaginationItem key={1}>
                        <PaginationLink
                            href='#'
                            className='h-8'
                            onClick={(e) => {
                                e.preventDefault();
                                handlePageChange(1);
                            }}
                        >
                            1
                        </PaginationLink>
                    </PaginationItem>
                );
                if (startPage > 2) {
                    items.push(
                        <PaginationItem key='start-ellipsis'>
                            <PaginationEllipsis />
                        </PaginationItem>
                    );
                }
            }

            // Tambahkan halaman di tengah
            for (let i = startPage; i <= endPage; i++) {
                items.push(
                    <PaginationItem key={i}>
                        <PaginationLink
                            href='#'
                            className='h-8'
                            isActive={i === currentPage}
                            onClick={(e) => {
                                e.preventDefault();
                                handlePageChange(i);
                            }}
                        >
                            {i}
                        </PaginationLink>
                    </PaginationItem>
                );
            }

            // Tambahkan halaman terakhir
            if (endPage < total_page) {
                if (endPage < total_page - 1) {
                    items.push(
                        <PaginationItem key='end-ellipsis'>
                            <PaginationEllipsis />
                        </PaginationItem>
                    );
                }
                items.push(
                    <PaginationItem key={total_page}>
                        <PaginationLink
                            href='#'
                            className='h-8'
                            onClick={(e) => {
                                e.preventDefault();
                                handlePageChange(total_page);
                            }}
                        >
                            {total_page}
                        </PaginationLink>
                    </PaginationItem>
                );
            }
        }

        return items;
    };

    return (
        <Pagination>
            <PaginationContent>
                <PaginationItem>
                    <PaginationPrevious
                        href='#'
                        className='h-8 px-2'
                        onClick={(e) => {
                            e.preventDefault();
                            if (currentPage > 1) handlePageChange(currentPage - 1);
                        }}
                    />
                </PaginationItem>
                {renderPaginationItems()}
                <PaginationItem>
                    <PaginationNext
                        href='#'
                        className='h-8 px-2'
                        onClick={(e) => {
                            e.preventDefault();
                            if (currentPage < total_page) handlePageChange(currentPage + 1);
                        }}
                    />
                </PaginationItem>
            </PaginationContent>
        </Pagination>
    );
}
