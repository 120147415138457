export const isEmail = (text: string) => {
    if (/^([+\w-]+(?:\.[+\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(text)) {
        return true;
    }
    return false;
};

export const getInitialName = (name: string): string => {
    if (!name) return '';

    // Pisahkan nama berdasarkan spasi
    const words = name.trim().split(' ');

    // Ambil huruf pertama dari maksimal 2 kata
    const initials = words
        .slice(0, 2)
        .map((word) => word[0].toUpperCase())
        .join('');

    return initials;
};

export const formatRupiah = (amount: number) => {
    const formatted = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        maximumFractionDigits: 0,
    }).format(amount);

    return formatted;
};
