import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { merchant } from '../../../api/endpoints';
import api from '../../../api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { notifications } from '@mantine/notifications';

const defaultValue = {
    image: '',
    name: '',
    address: '',
    description: '',
    phone_number: '',
    type: '',
};

function FormHooks() {
    const navigate = useNavigate();
    const { id } = useParams();
    const { data: detailMerchant = defaultValue } = useQuery(
        ['detail_merchant'],
        async () => {
            const url = generatePath(merchant.findByID, { id });
            const res = await api.get(url);

            if (!res?.data?.status) {
                notifications.show({
                    message: 'Ups, terjadi kesalahan saat fetch detail merchant.',
                    color: 'red',
                });
                return defaultValue;
            }
            return res?.data?.data;
        },
        {
            enabled: id !== undefined,
        }
    );
    const {
        register,
        control,
        watch,
        formState: { errors },
        setValue,
        handleSubmit,
    } = useForm();
    const [file, setFile] = useState<File>();

    useEffect(() => {
        setValue('image', id ? detailMerchant.image : defaultValue.image);
        setValue('name', id ? detailMerchant.name : defaultValue.name);
        setValue('address', id ? detailMerchant.address : defaultValue.address);
        setValue('description', id ? detailMerchant.description : defaultValue.description);
        setValue('phone_number', id ? detailMerchant.phone_number : defaultValue.phone_number);
        setValue('type', id ? detailMerchant.type : defaultValue.type);
    }, [detailMerchant, id]);

    const handleUploadImage = async (value: File) => {
        setFile(value);
        setValue('image', URL.createObjectURL(value));
    };

    const mutation = useMutation({
        mutationFn: (data: FormData) =>
            api.post(id ? generatePath(merchant.update, { id }) : merchant.create, data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            }),
        onSuccess: () => {
            navigate('/merchant');
        },
        onError: () => {
            notifications.show({
                message: 'Ups, terjadi kesalahan saat submit merchant.',
                color: 'red',
            });
        },
    });

    const onSubmit = async (data: FieldValues) => {
        const formData = new FormData();
        if (file) {
            formData.append('image', file);
        }
        Object.keys(data)
            .filter((key) => key !== 'image')
            .forEach((key) => {
                formData.append(key, data[key]);
            });
        mutation.mutate(formData);
    };

    return {
        register,
        control,
        watch,
        errors,
        handleSubmit,
        loading: mutation.isLoading,
        handleUploadImage,
        onSubmit,
        file,
    };
}

export default FormHooks;
