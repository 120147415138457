import { Button, Input, PasswordInput, PinInput, TextInput } from '@mantine/core';
import { Link } from 'react-router-dom';
import AuthLayout from '../../layout/authentication';
import { isEmail } from '../../lib/helper';
import RegisterHooks from './RegisterHooks';

const Register = () => {
    const {
        register,
        errors,
        searchParams,
        time,
        otp,
        loading,
        handleSubmitRegistrationData,
        handleChangeOTP,
        handleSubmitOTP,
        handleResendOTP,
    } = RegisterHooks();

    return (
        <AuthLayout title={searchParams.get('segment') === 'register' ? 'Registrasi Akun' : 'Konfirmasi OTP'}>
            {/* registration */}
            {searchParams.get('segment') === 'register' && (
                <>
                    <div className='flex flex-col gap-3 mb-7 w-[350px]'>
                        {/* name */}
                        <Input.Wrapper
                            label='Nama'
                            error={errors?.name?.message ? String(errors?.name?.message) : null}
                            styles={() => ({
                                label: { fontWeight: 400 },
                                error: {
                                    visibility: errors?.name ? 'visible' : 'hidden',
                                },
                            })}
                            required
                        >
                            <TextInput
                                {...register('name', {
                                    required: {
                                        value: true,
                                        message: 'Nama wajib diisi!',
                                    },
                                })}
                                placeholder='Masukan nama'
                                error={Boolean(errors?.name)}
                            />
                        </Input.Wrapper>
                        {/* email */}
                        <Input.Wrapper
                            label='Email'
                            error={errors?.email?.message ? String(errors?.email?.message) : null}
                            styles={() => ({
                                label: { fontWeight: 400 },
                                error: {
                                    visibility: errors?.email ? 'visible' : 'hidden',
                                },
                            })}
                            required
                        >
                            <TextInput
                                {...register('email', {
                                    required: {
                                        value: true,
                                        message: 'Email wajib diisi!',
                                    },
                                    validate: {
                                        notEmail: (value: string) =>
                                            isEmail(value) || 'Email tidak sesuai dengan format. Contoh: name@mail.com',
                                    },
                                    setValueAs(value: string) {
                                        return value?.toLowerCase();
                                    },
                                })}
                                placeholder='Masukan email'
                                error={Boolean(errors?.email)}
                            />
                        </Input.Wrapper>
                        <Input.Wrapper
                            label='Password'
                            error={errors?.password?.message ? String(errors?.password?.message) : null}
                            styles={() => ({
                                label: { fontWeight: 400 },
                                error: {
                                    visibility: errors?.password ? 'visible' : 'hidden',
                                },
                            })}
                            required
                        >
                            <PasswordInput
                                {...register('password', {
                                    required: {
                                        value: true,
                                        message: 'Password wajib diisi!',
                                    },
                                })}
                                placeholder='Masukan password'
                                error={Boolean(errors?.password)}
                            />
                        </Input.Wrapper>
                    </div>
                    <Button
                        fullWidth
                        className='bg-blue-700'
                        onClick={handleSubmitRegistrationData}
                        loading={loading === 'register'}
                    >
                        Register
                    </Button>
                    <div className='pt-5 text-sm text-center'>
                        Sudah memiliki akun?{' '}
                        <Link className='text-blue-700 cursor-pointer hover:underline' to='/login'>
                            masuk disini
                        </Link>
                    </div>
                </>
            )}

            {/* OTP */}
            {searchParams.get('segment') === 'otp' && (
                <div className='flex flex-col gap-24'>
                    <div className='flex flex-col gap-1.5'>
                        <PinInput
                            size='xl'
                            length={6}
                            type='number'
                            className='justify-center'
                            placeholder=''
                            onChange={handleChangeOTP}
                            value={otp}
                        />
                        {/* countdown time for input otp */}
                        {time !== 0 && (
                            <div className='text-xs text-gray-500 text-end'>
                                Kirim ulang dalam <span className='text-blue-600'>{time} detik</span>
                            </div>
                        )}

                        {/* resend otp */}
                        {time === 0 && (
                            <div className='text-xs text-gray-500 text-end'>
                                Belum mendapatkan kode?{' '}
                                <span
                                    aria-hidden
                                    className='text-blue-600 cursor-pointer hover:underline'
                                    onClick={handleResendOTP}
                                >
                                    kirim ulang
                                </span>
                            </div>
                        )}
                    </div>
                    <Button fullWidth className='bg-blue-700' onClick={handleSubmitOTP} disabled={otp === ''}>
                        Konfirmasi
                    </Button>
                </div>
            )}
        </AuthLayout>
    );
};

export default Register;
