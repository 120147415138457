import { Modal } from '@mantine/core';
import { AiOutlinePlus } from 'react-icons/ai';
import CreateHooks from './CreateHooks';
import { GrFormClose } from 'react-icons/gr';
import Form from '../form';
import { Button } from '@/components/ui/button';

type Props = {
    refetch: () => void;
};

const Create: React.FC<Props> = (props) => {
    const { refetch } = props;
    const { modal, setModal } = CreateHooks();

    return (
        <>
            <Button onClick={() => setModal(true)} className='h-8'>
                <AiOutlinePlus />
                Tambah Produk
            </Button>

            <Modal
                opened={modal}
                onClose={() => setModal(false)}
                withCloseButton={false}
                padding={20}
                radius='md'
                size={400}
            >
                <div className='relative'>
                    <div className='mb-5 text-xl font-semibold text-center'>Tambah Produk</div>
                    <GrFormClose
                        className='absolute text-xl cursor-pointer -right-2 -top-1'
                        onClick={() => setModal(false)}
                    />
                </div>
                <Form refetch={refetch} handleClose={() => setModal(false)} />
            </Modal>
        </>
    );
};

export default Create;
