import { CartItem, useAddToCart, useRemoveFromCart } from '@/hooks/use-cart';
import { TbBaguette } from 'react-icons/tb';
import { formatRupiah } from '@/lib/helper';
import { MinusSquare, PlusSquare, ShoppingBag } from 'lucide-react';
import { Card } from '@/components/ui/card';
import { AvatarImage, Avatar, AvatarFallback } from '@/components/ui/avatar';

export default function OrderList({ cart }: { cart: CartItem[] }) {
    const merchantID = localStorage.getItem('merchantID')!;
    const { mutate: removeFromCart } = useRemoveFromCart(merchantID);
    const { mutate: addToCart } = useAddToCart(merchantID);

    return (
        <div className='space-y-2'>
            {cart.map((each, key) => (
                <Card className='relative flex items-center gap-4 p-2' key={key}>
                    <Avatar className='w-16 h-16 rounded-md'>
                        <AvatarImage src={each.cover?.url} alt={each.name} />
                        <AvatarFallback className='rounded-lg'>
                            <TbBaguette className='text-3xl' />
                        </AvatarFallback>
                    </Avatar>
                    <div className='flex flex-col justify-between flex-1 gap-2'>
                        <div>
                            <div className='text-sm font-semibold'>{each.name}</div>
                            <div className='text-sm '>Stok &#x2022; {each.stock}</div>
                        </div>
                        <div className='flex justify-between'>
                            <div className='text-xs font-semibold text-gray-500'>
                                {formatRupiah(each.price * each.qty)}
                            </div>
                            <div className='flex flex-row items-center gap-1'>
                                <MinusSquare className='cursor-pointer' onClick={() => removeFromCart(each.id)} />
                                <div className='text-sm'>{each.qty}</div>

                                <PlusSquare
                                    onClick={() => (each.qty < each.stock ? addToCart({ ...each }) : null)}
                                    className={`cursor-pointer ${
                                        each.qty >= each.stock ? 'text-primary/40' : 'text-primary'
                                    }`}
                                />
                            </div>
                        </div>
                    </div>
                </Card>
            ))}
            {/* empty state cart */}
            {cart.length === 0 && (
                <div className='flex flex-col items-center justify-center gap-2 py-10'>
                    <div className='text-5xl text-center'>
                        <ShoppingBag className='mx-auto ' size={30} />
                    </div>
                    <div className='text-sm text-center text-gray-600'>Silahkan tambahkan produk</div>
                </div>
            )}
        </div>
    );
}
