import { SearchIcon } from 'lucide-react';

type SearchProps = React.InputHTMLAttributes<HTMLInputElement>;
export default function Search({ ...props }: SearchProps) {
    return (
        <div className='flex items-center gap-2 p-2 border rounded-md min-w-xs'>
            <SearchIcon size={14} className='text-gray-400' />
            <input placeholder='Pencarian' className='outline-none' {...props} />
        </div>
    );
}
