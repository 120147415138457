import { Modal } from '@mantine/core';
import { BiCategory } from 'react-icons/bi';
import { GrFormClose } from 'react-icons/gr';
import CategoryHooks from './CategoryHooks';
import { ICategory } from '../../../interface/Category';
import Create from './create';
import Edit from './edit';
import { Button } from '@/components/ui/button';

const ProductCategory = () => {
    const { productCategory, refetch, modal, setModal } = CategoryHooks();

    return (
        <>
            <Button variant='outline' className='h-8' onClick={() => setModal(true)}>
                <BiCategory />
                Kategori Produk
            </Button>

            <Modal
                opened={modal}
                onClose={() => setModal(false)}
                withCloseButton={false}
                padding={0}
                radius='md'
                size={350}
            >
                <div className='flex items-center justify-between px-5 py-4 mb-5 border-b'>
                    <div className='text-lg font-semibold'>Kategori Produk</div>
                    <GrFormClose className='cursor-pointer' onClick={() => setModal(false)} />
                </div>
                <div className='px-5 max-h-[300px] overflow-auto flex flex-col gap-2'>
                    {productCategory?.length ? (
                        productCategory?.map((each: ICategory, key: number) => (
                            <div className='flex items-center justify-between p-3 rounded shadow' key={key}>
                                <div className='text-sm font-medium'>{each.name}</div>
                                <Edit data={each} refetch={refetch} />
                            </div>
                        ))
                    ) : (
                        <div>
                            <img src='/empty_illustation.svg' alt='empty data' className='ml-auto mr-auto' />
                            <div className='text-lg font-medium text-center'>Belum memiliki kategori produk</div>
                            <div className='text-sm text-center text-gray-500'>Silahkan tambahkan kategori</div>
                        </div>
                    )}
                </div>
                <div className='p-5'>
                    <Create refetch={refetch} />
                </div>
            </Modal>
        </>
    );
};

export default ProductCategory;
