import { type LucideIcon } from 'lucide-react';
import {
    SidebarGroup,
    SidebarGroupLabel,
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
} from '@/components/ui/sidebar';
import { Link, useLocation } from 'react-router-dom';

export function NavMain({
    items,
}: {
    items: {
        name: string;
        url: string;
        icon: LucideIcon;
        isShowing: boolean;
    }[];
}) {
    const { pathname } = useLocation();
    return (
        <SidebarGroup>
            <SidebarGroupLabel>Features</SidebarGroupLabel>
            <SidebarMenu>
                {items.map((item) => (
                    <SidebarMenuItem key={item.name}>
                        <SidebarMenuButton asChild isActive={pathname.includes(item.url)}>
                            <Link to={item.url} className={item.isShowing ? 'block' : 'hidden'}>
                                <item.icon />
                                <span>{item.name}</span>
                            </Link>
                        </SidebarMenuButton>
                    </SidebarMenuItem>
                ))}
            </SidebarMenu>
        </SidebarGroup>
    );
}
