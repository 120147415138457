import { Separator } from '@/components/ui/separator';
import { formatRupiah } from '@/lib/helper';
import { ShoppingBag } from 'lucide-react';
import CartMobile from './cart-mobile';
import ShopingCart from './shoping-cart';
import { useCart } from '@/hooks/use-cart';
import ProductList from './product-list';
import CategoryList from './category-list';

export default function Cashier() {
    const merchantID = localStorage.getItem('merchantID')!;
    const { data } = useCart(merchantID);
    const carts = data?.cart ?? [];

    return (
        <>
            <div className='relative space-y-2 '>
                <div className='grid grid-cols-12 gap-4 '>
                    <div className='col-span-12 md:col-span-7 lg:col-span-8 xl:col-span-9 '>
                        <div className='min-h-[calc(100vh-10rem)] space-y-2'>
                            <div className='flex flex-row gap-3'>
                                <CategoryList />
                            </div>
                            <Separator />
                            <ProductList />
                        </div>
                        {carts.length > 0 && (
                            <div className='sticky left-0 right-0 block max-w-md mx-auto md:hidden bottom-4'>
                                <CartMobile>
                                    <div className='flex p-3 px-5 mx-3 space-x-2 text-white rounded-full cursor-pointer bg-primary'>
                                        <ShoppingBag size={24} className='text-white' />
                                        <span className='flex-grow font-semibold '>{carts.length} Pesanan</span>
                                        <span>{formatRupiah(data?.totalPayment ?? 0)}</span>
                                    </div>
                                </CartMobile>
                            </div>
                        )}
                    </div>

                    <div className='sticky self-start hidden pt-2 md:col-span-5 lg:col-span-4 xl:col-span-3 md:block top-16'>
                        <ShopingCart />
                    </div>
                </div>
            </div>
        </>
    );
}
