import { Modal } from '@mantine/core';
import { BiPencil } from 'react-icons/bi';
import { GrFormClose } from 'react-icons/gr';
import EditHooks from './EditHooks';
import Form from '../form';
import { IProduct } from '../../../interface/Product';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
    data: IProduct;
};

const Edit: React.FC<Props> = (props) => {
    const queryClient = useQueryClient();

    const refetch = () => {
        queryClient.invalidateQueries(['products']);
    };

    const { data } = props;
    const { modal, setModal } = EditHooks();

    return (
        <>
            <BiPencil className='text-lg text-blue-700 cursor-pointer' onClick={() => setModal(true)} />

            <Modal
                opened={modal}
                onClose={() => setModal(false)}
                withCloseButton={false}
                padding={20}
                radius='md'
                size={400}
            >
                <div className='relative'>
                    <div className='mb-5 text-xl font-semibold text-center'>Edit Produk</div>
                    <GrFormClose
                        className='absolute text-xl cursor-pointer -right-2 -top-1'
                        onClick={() => setModal(false)}
                    />
                </div>
                <Form data={data} refetch={refetch} handleClose={() => setModal(false)} />
            </Modal>
        </>
    );
};

export default Edit;
