import api from '@/api';
import { merchant } from '@/api/endpoints';
import { notifications } from '@mantine/notifications';
import { useQuery } from '@tanstack/react-query';
import { generatePath, useNavigate } from 'react-router-dom';

export function useMerchantDetail() {
    const navigate = useNavigate();

    // fetching detail merchant
    const { data: detailMerchant } = useQuery(['detail_merchant'], async () => {
        const url = generatePath(merchant.findByID, { id: localStorage.getItem('merchantID') });
        const res = await api.get(url);

        if (!res?.data?.status) {
            notifications.show({
                message: 'Ups, terjadi kesalahan saat fetch detail merchant.',
                color: 'red',
            });
        }
        return res?.data?.data;
    });

    const handleChooseMerchant = () => {
        navigate('/merchant');
        localStorage.removeItem('merchantID');
    };

    const handleLogout = () => {
        navigate('/login');
        localStorage.clear();
    };

    return {
        detailMerchant,
        handleChooseMerchant,
        handleLogout,
    };
}

export function useMerchants() {
    const navigate = useNavigate();

    const { data, isFetching } = useQuery({
        queryKey: ['merchants'],
        queryFn: async () => api.get(merchant.fetchAll),
        cacheTime: 1000 * 60 * 60,
        staleTime: 1000 * 60 * 10,
    });

    // function for handle select merchant
    const handleSelectMerchant = (id: number) => {
        localStorage.setItem('merchantID', String(id));
        navigate('/app/dashboard');
        window.location.reload();
    };

    return {
        merchantData: data?.data?.data ?? [],
        isFetching,
        handleSelectMerchant,
    };
}
