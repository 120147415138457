import api from '@/api';
import { product_category, products } from '@/api/endpoints';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from './use-debounce';

export function useProducts() {
    const queryClient = useQueryClient();
    const merchantID = localStorage.getItem('merchantID');
    const { data, isFetching, refetch } = useQuery({
        queryKey: ['products', localStorage.getItem('merchantID')],
        queryFn: async () => {
            const search = queryClient.getQueryData(['q']) || '';
            const category = queryClient.getQueryData(['category']) || '';
            const page = queryClient.getQueryData(['page']) || '';
            const params: any = {
                merchant_id: merchantID,
                page: 1,
                per_page: 100,
            };

            if (search) {
                params.q = search;
            }

            if (category) {
                params.category_id = category;
            }

            if (page) {
                params.page = page;
            }

            return api
                .get(products.fetch, {
                    params,
                })
                .then((res) => res.data);
        },
        cacheTime: 1000 * 60 * 60,
        staleTime: 1000 * 60 * 10,
        enabled: !!localStorage.getItem('merchantID'),
    });

    return {
        products: data?.data ?? [],
        total_page: data?.last_page,
        current_page: data?.current_page,
        isFetching,
        refetch,
    };
}

export function useCategories() {
    const merchantID = localStorage.getItem('merchantID');
    const { data, isFetching } = useQuery({
        queryKey: ['categories_cashier', localStorage.getItem('merchantID')],
        queryFn: async () => {
            const params: any = {
                merchant_id: merchantID,
            };

            return api
                .get(product_category.fetch, {
                    params,
                })
                .then((res) => res.data);
        },
    });

    return {
        categories: data?.data ?? [],
        isFetching,
    };
}

export function useProductParams(property: string, debounce?: number) {
    const queryClient = useQueryClient();
    const [value, setValue] = useState<string>('');
    const debouncedSearchTerm = useDebounce(value, debounce ?? 700);

    const onChangeValue = useCallback(() => {
        queryClient.setQueryData([property], debouncedSearchTerm);
        queryClient.invalidateQueries({
            queryKey: ['products', localStorage.getItem('merchantID')],
        });
    }, [debouncedSearchTerm, queryClient]);

    useEffect(() => {
        onChangeValue();
    }, [onChangeValue]);

    return { value, setValue };
}
