import { DatesRangeValue } from '@mantine/dates';
import { notifications } from '@mantine/notifications';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { IReportParams } from '@/interface/Report';
import { useState } from 'react';
import api from '../../api';
import { transaction } from '../../api/endpoints';

function DashboardHooks() {
    const merchant_id = parseInt(localStorage.getItem('merchantID') ?? '0');
    const firstday = dayjs().startOf('year').format('YYYY-MM-D');
    const lastday = dayjs().endOf('year').format('YYYY-MM-D');
    const [params, setParams] = useState<IReportParams>({
        merchant_id: merchant_id,
        start_date: firstday,
        end_date: lastday,
    });
    const [summaryParams, setSummaryParams] = useState<string>(String(dayjs().year()));

    // fetching transaction report
    const { data: transactionReport, isFetching: isFetchingTransactionReport } = useQuery(
        ['transaction_report', params],
        async () => {
            const res = await api.get(transaction.report, {
                params: params,
            });

            if (!res?.data?.status) {
                notifications.show({
                    message: 'Ups, terjadi kesalahan saat fetch laporan transaksi.',
                    color: 'red',
                });
            }
            return res?.data;
        }
    );

    // fetching transaction report summary
    const { data: transactionReportSummary, isFetching: isFetchingTransactionReportSummary } = useQuery(
        ['transaction_report_summary', summaryParams],
        async () => {
            const res = await api.get(transaction.report_summary, {
                params: {
                    merchant_id: merchant_id,
                    start_date: `${summaryParams}-01-1`,
                    end_date: `${summaryParams}-12-31`,
                },
            });

            if (!res?.data?.status) {
                notifications.show({
                    message: 'Ups, terjadi kesalahan saat fetch laporan transaksi.',
                    color: 'red',
                });
            }
            return res?.data;
        }
    );

    // function for handle change date filter
    const handleFilterDate = (value: DatesRangeValue) => {
        console.log(value);
        const start_date = dayjs(value[0]).format('YYYY-MM-D');
        const end_date = value[1] === null ? undefined : dayjs(value[1]).format('YYYY-MM-D');

        setParams({
            ...params,
            start_date: start_date,
            end_date: end_date,
        });

        // set params if both of value null
        !value[0] && !value[1] && setParams({ ...params, start_date: firstday, end_date: lastday });
    };

    // function for generate year option on transaction summary chart
    const generateYearOption = () => {
        const years = [];

        for (let i = dayjs().year(); i >= 2023; i--) {
            years.push({ value: String(i), label: String(i) });
        }

        return years;
    };

    return {
        firstday,
        lastday,
        params,
        setParams,
        summaryParams,
        setSummaryParams,
        transactionReport,
        isFetchingTransactionReport,
        transactionReportSummary,
        isFetchingTransactionReportSummary,
        handleFilterDate,
        generateYearOption,
    };
}

export default DashboardHooks;
