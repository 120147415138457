import { Button, Modal } from '@mantine/core';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';
import DeleteHooks from './DeleteHooks';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
    productName: string;
    productID: number;
};

const Delete: React.FC<Props> = (props) => {
    const queryClient = useQueryClient();

    const refetch = () => {
        queryClient.invalidateQueries(['products']);
    };

    const { productName, productID } = props;
    const { modal, setModal, loading, handleDelete } = DeleteHooks(refetch);

    return (
        <>
            <RiDeleteBinLine
                aria-hidden
                className='text-lg text-red-700 cursor-pointer'
                onClick={() => setModal(true)}
            />

            <Modal
                opened={modal}
                onClose={() => setModal(false)}
                withCloseButton={false}
                radius='lg'
                size={350}
                padding={30}
                centered
            >
                <MdOutlineDeleteOutline className='mx-auto mb-1 text-red-600 text-8xl' />
                <div className='mb-1 text-lg font-semibold text-center'>Anda yakin akan menghapus produk ini?</div>
                <div className='mb-4 text-sm text-center text-gray-500'>{productName}</div>
                <div className='grid grid-cols-2 gap-3'>
                    <Button variant='outline' onClick={() => setModal(false)}>
                        Batal
                    </Button>
                    <Button
                        color='red'
                        className='bg-red-600'
                        loading={loading}
                        onClick={() => handleDelete(productID)}
                    >
                        Hapus
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default Delete;
