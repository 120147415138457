import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';

import { AppPagination } from './app-pagination';
import React from 'react';
import { useMediaQuery } from '@/hooks/use-media-query';

interface DataTableProps<TData, TValue> {
    columns: ColumnDef<TData, TValue>[];
    data: TData[];
    total_page?: number;
    onChangePage?: (val: number) => void;
    current_page?: number;
}

export function DataTable<TData, TValue>({
    columns,
    data,
    total_page,
    onChangePage,
    current_page,
}: DataTableProps<TData, TValue>) {
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    const isMobile = useMediaQuery('(max-width:768px)');

    const label = [...columns].map((val) => val.header?.toString()) ?? [];

    return (
        <>
            <div>
                <Table className=' data-table'>
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id} className='border rounded-md'>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <TableHead key={header.id} className='p-2'>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(header.column.columnDef.header, header.getContext())}
                                        </TableHead>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody className={isMobile ? '[&_tr:last-child]:border' : ''}>
                        {!isMobile && <tr className='h-2 ' />}
                        {table.getRowModel().rows?.length ? (
                            table.getRowModel().rows.map((row) => {
                                return (
                                    <React.Fragment key={row.id}>
                                        <TableRow
                                            data-state={row.getIsSelected() && 'selected'}
                                            className='border custome-tr'
                                        >
                                            {row.getVisibleCells().map((cell, key) => (
                                                <TableCell key={cell.id} aria-label={label?.[key]} className='p-2'>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        {!isMobile && <tr className='h-2 ' />}
                                    </React.Fragment>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} className='h-24 text-center border table-empty'>
                                    No results.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            {total_page! > 0 && (
                <AppPagination
                    total_page={total_page!}
                    onChangePage={(val) => onChangePage?.(val)}
                    current_page={current_page}
                />
            )}
        </>
    );
}
