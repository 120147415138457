import { Image, Loader, Modal } from '@mantine/core';
import { GrFormClose } from 'react-icons/gr';
import { IProduct } from '../../../interface/Product';
import UploadHooks from './UploadHooks';
import { MdOutlineAddPhotoAlternate } from 'react-icons/md';
import { UploadImage } from '../../../components';
import { TbBaguette, TbTrash } from 'react-icons/tb';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
    data: IProduct;
};

const UploadImages: React.FC<Props> = (props) => {
    const queryClient = useQueryClient();
    const { modal, setModal, images, isFetching, handleUploadImage, image, isUploading, handleDelete } = UploadHooks(
        props.data.id
    );

    const refetch = () => {
        queryClient.invalidateQueries({
            queryKey: ['products'],
        });
    };

    return (
        <>
            <div
                className='w-[50px] h-[50px] border bg-muted/50 rounded-lg items-center justify-center flex cursor-pointer'
                onClick={() => setModal(true)}
            >
                <MdOutlineAddPhotoAlternate size={20} />
            </div>
            <Modal
                opened={modal}
                onClose={() => {
                    setModal(false);
                    refetch();
                }}
                withCloseButton={false}
                padding={20}
                radius='md'
                size={400}
            >
                <div className='relative'>
                    <div className='mb-5 text-xl font-semibold text-center'>Gambar Produk</div>
                    <GrFormClose
                        className='absolute text-xl cursor-pointer -right-2 -top-1'
                        onClick={() => {
                            setModal(false);
                            refetch();
                        }}
                    />
                </div>
                {isFetching ? (
                    'Loading...'
                ) : (
                    <div>
                        {images?.data?.length < 6 && (
                            <div className='relative'>
                                <UploadImage
                                    src={image}
                                    accept='image/jpg,image/jpeg,image/png'
                                    handleChange={(val) => handleUploadImage(val)}
                                />
                                {isUploading && (
                                    <div className='absolute top-0 bottom-0 left-0 right-0 pt-[40px] px-4 bg-white/80 flex justify-center'>
                                        <Loader size={30} />
                                    </div>
                                )}
                            </div>
                        )}
                        <div className='grid grid-cols-3 gap-2 mt-3'>
                            {images?.data?.map((val: { url: string; id: number }, key: number) => (
                                <div className='relative' key={key}>
                                    <Image
                                        fit='cover'
                                        height={100}
                                        src={val?.url}
                                        withPlaceholder
                                        placeholder={<TbBaguette className='text-3xl' />}
                                        radius='md'
                                    />
                                    <div
                                        className='absolute p-1 bg-white rounded-sm cursor-pointer top-1 right-1'
                                        onClick={() => handleDelete(val.id)}
                                    >
                                        <TbTrash size={15} color='red' />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default UploadImages;
