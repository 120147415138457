import { ColumnDef } from '@tanstack/react-table';
import Delete from './delete';
import { IListEmployee } from '@/interface/Employee';

export const columns: ColumnDef<IListEmployee>[] = [
    {
        accessorKey: 'user_name',
        header: 'Nama',
    },

    {
        accessorKey: 'user_email',
        header: 'Alamat Email',
    },

    {
        id: 'actions',
        cell: ({ row }) => {
            const data = row.original;
            return (
                <>
                    <Delete id={data?.user_id} name={data?.user_name} />
                </>
            );
        },
    },
];
