import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useFormOngoingTransaction } from '@/hooks/use-transaction';
import DetailTransaction from '@/components/detail-transaction';
import { Button } from '@/components/ui/button';
import { IListTransaction } from '@/interface/Transaction';

export default function Detail({ data }: { data: IListTransaction }) {
    const {
        detailTransaction,
        setDetailTransaction,
        control,
        errors,
        watch,
        cart,
        handleSubmit,
        setIsPay,
        isPay,
        processing,
        isFetchingDetail,
        isCancelling,
        handleCancelTransaction,
    } = useFormOngoingTransaction();

    return (
        <div>
            <Button className='relative h-8' onClick={() => setDetailTransaction(data)}>
                Detail
            </Button>
            <Dialog
                open={!!detailTransaction}
                onOpenChange={() => {
                    setDetailTransaction(null);
                }}
            >
                <DialogContent className='gap-2'>
                    <DialogHeader>
                        <DialogTitle>Detail Transaksi</DialogTitle>
                        <DialogDescription>
                            Berikut adalah detail anda. Silakan selesaikan pembayaran jika transaksi sudah dibayarkan.
                        </DialogDescription>
                    </DialogHeader>

                    <DetailTransaction
                        control={control}
                        cart={cart}
                        detailTransaction={detailTransaction}
                        errors={errors}
                        handleCancelTransaction={handleCancelTransaction}
                        handleSubmit={handleSubmit}
                        isCancelling={isCancelling}
                        isFetchingDetail={isFetchingDetail}
                        isPay={isPay}
                        processing={processing}
                        setDetailTransaction={setDetailTransaction}
                        setIsPay={setIsPay}
                        watch={watch}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
}
