import { IProduct } from '@/interface/Product';
import { formatRupiah } from '@/lib/helper';
import { Image } from '@mantine/core';
import { ColumnDef } from '@tanstack/react-table';
import { TbBaguette } from 'react-icons/tb';
import UploadImages from './uploadImages';
import Edit from './edit';
import Delete from './delete';
// import UploadImages from './uploadImages';

export const columns: ColumnDef<IProduct>[] = [
    {
        accessorKey: 'Gambar',
        header: 'Gambar',
        cell: ({ row }) => {
            const cover: { url: string } | null = row.original.cover;
            return (
                <div className='flex gap-2 '>
                    {cover?.url && (
                        <Image
                            width={50}
                            height={50}
                            fit='cover'
                            src={cover?.url}
                            withPlaceholder
                            placeholder={<TbBaguette className='text-3xl' />}
                            radius='md'
                        />
                    )}
                    <UploadImages data={row.original} />
                </div>
            );
        },
    },
    {
        accessorKey: 'name',
        header: 'Nama Produk',
    },
    { accessorKey: 'stock', header: 'Stok' },
    {
        accessorKey: 'buying_price',
        header: 'Harga Beli',
        cell: ({ row }) => {
            return <div className='font-medium'>{formatRupiah(parseFloat(row.getValue('buying_price')))}</div>;
        },
    },
    {
        accessorKey: 'selling_price',
        header: 'Harga Jual',
        cell: ({ row }) => {
            return <div className='font-medium'>{formatRupiah(parseFloat(row.getValue('selling_price')))}</div>;
        },
    },
    {
        id: 'actions',
        header: 'Aksi',
        cell: ({ row }) => {
            return (
                <div className='flex gap-2'>
                    <Edit data={row.original} />
                    <Delete productName={row?.original.name} productID={row.original?.id} />
                </div>
            );
        },
    },
];
