import { ReactNode } from 'react';

type Props = {
    withLogout?: boolean;
    children: ReactNode;
};

const MerchantLayout: React.FC<Props> = (props) => {
    const { children } = props;

    return (
        <div className='relative content-center w-screen h-screen bg-gray-100'>
            <div className='max-w-sm p-2 mx-auto'>{children}</div>
        </div>
    );
};

export default MerchantLayout;
