import * as React from 'react';
import { NavUser } from '@/components/nav-user';
import { MerchantSwitcher } from '@/components/merchant-switcher';
import {
    Sidebar,
    SidebarContent,
    SidebarFooter,
    SidebarHeader,
    SidebarRail,
    useSidebar,
} from '@/components/ui/sidebar';
import { NavMain } from './nav-main';
import useMenus from '@/hooks/use-menus';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '@/hooks/use-media-query';

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
    const { menus } = useMenus();
    const user = {
        name: localStorage.getItem('username')!,
        role: localStorage.getItem('role')!,
        avatar: '/avatars/shadcn.jpg',
    };

    const { setOpen } = useSidebar();
    const { pathname } = useLocation();
    const isDesktop = useMediaQuery('(min-width: 1024px)');
    const setCollapsed = React.useCallback(() => {
        if (!isDesktop && pathname.includes('/app/cashier')) {
            setOpen(false);
        }
    }, [pathname, setOpen, isDesktop]);

    React.useEffect(() => {
        setCollapsed();
    }, [setCollapsed]);

    return (
        <Sidebar collapsible='icon' {...props}>
            <SidebarHeader>
                <MerchantSwitcher />
            </SidebarHeader>
            <SidebarContent>
                <NavMain items={menus} />
            </SidebarContent>
            <SidebarFooter>
                <NavUser user={user} />
            </SidebarFooter>
            <SidebarRail />
        </Sidebar>
    );
}
